import styles from "./ShippingReport.module.css";

import {
	Button,
	ComboBox,
	ComboBoxItem,
	DateRangePicker,
	FlexBox,
	Form,
	FormGroup,
	FormItem,
	Label,
	Table,
	TableCell,
	TableColumn,
	TableRow,
} from "@ui5/webcomponents-react";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import { Customer } from "../../models/customer.model";
import { Quotation } from "../../models/quotation.model";
import { CSVLink } from "react-csv";

export function ShippingReport() {
	/**
	 * Shipping Report global props
	 */
	const [shippings, setShippings] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageNavigator, setPageNavigator] = useState<number>(1);
	const [filterType, setFilterType] = useState<string>("");
	const [filterValue, setFilterValue] = useState<string>("");
	const history = useHistory();
	const [cookie] = useCookies(["access"]);
	const apiAccess = {
		headers: { Authorization: `Bearer ${cookie["access"]}` },
	};
	const ITEMS_PER_PAGE: number = 50;

	const [quotations, setQuotations] = useState<Quotation[]>([]);
	const [customers, setCustomers] = useState<Customer[]>([]);

	/**
	 * Get global init props
	 */
	useEffect(() => {
		const apiAccess = {
			headers: { Authorization: `Bearer ${cookie["access"]}` },
		};
		axios.get(`${API_URL}/crud/shipping`, apiAccess).then((response: any) => {
			setShippings(response.data);
		});

		axios
			.post(`${API_URL}/query/shipping-report`, {}, apiAccess)
			.then((response: any) => {
				setShippings(response.data.rows);
			});
		axios.get(`${API_URL}/crud/quotation`, apiAccess).then((response: any) => {
			setQuotations(response.data);
		});
		axios.get(`${API_URL}/crud/customer`, apiAccess).then((response: any) => {
			setCustomers(response.data);
		});
	}, [cookie]);

	/**
	 * Converts date to locale
	 * @param date Unclean date
	 * @returns Clean date
	 */
	const cleanDate = (date: any): string => {
		if (date === undefined || date === "" || date === null) {
			return "";
		}
		let result = new Date(date.toString());
		return result
			.toLocaleString("es-GT")
			.split(" ")[0];
	};

	/**
	 * Pagination
	 */
	const indexOfLastItem: number = currentPage * ITEMS_PER_PAGE;
	const indexOfFirtsItem: number = indexOfLastItem - ITEMS_PER_PAGE;
	const currentShppings: any[] = shippings.slice(
		indexOfFirtsItem,
		indexOfLastItem
	);
	const pageNumber: number = Math.ceil(shippings.length / ITEMS_PER_PAGE);
	const pageAvailable: number = pageNumber - pageNavigator;
	const pageNumbers = [];
	for (
		let i = pageNavigator;
		i <= pageNavigator + (pageAvailable < 5 ? pageAvailable : 5);
		i++
	) {
		pageNumbers.push(i);
	}

	/**
	 * Filter constants
	 */
	const FILTER_BY_QUOTATION: string = "Número de Proforma";
	const FILTER_BY_CUSTOMER_NAME: string = "Nombre de cliente";
	const FILTER_BY_DATE_RANGE: string = "Rango de fecha";

	/**
	 * Handles shipping filter
	 */
	const handleFilter = async () => {
		switch (filterType) {
			case FILTER_BY_QUOTATION:
				try {
					const response = await axios.post(
						`${API_URL}/query/shipping-report-quotation`,
						{ filterValue: filterValue },
						apiAccess
					);
					setShippings(response.data.rows);
				} catch (error) {}
				break;
			case FILTER_BY_CUSTOMER_NAME:
				try {
					const response = await axios.post(
						`${API_URL}/query/shipping-report-customer-name`,
						{ filterValue: filterValue },
						apiAccess
					);
					setShippings(response.data.rows);
				} catch (error) {}

				break;
			case FILTER_BY_DATE_RANGE:
				try {
					const startDate = new Date(filterValue.split("-")[0])
						.toISOString()
						.split("T")[0];
					const endDate = new Date(filterValue.split("-")[1])
						.toISOString()
						.split("T")[0];
					const response = await axios.post(
						`${API_URL}/query/shipping-report-date`,
						{ start: startDate, end: endDate },
						apiAccess
					);
					setShippings(response.data.rows);
				} catch (error) {}
				break;
		}
	};

	/**
	 * Opens an file
	 * @param url File url
	 */
	const viewFile = async (url: any) => {
		fetch(url, apiAccess)
			.then((response) => response.blob())
			.then((blob) => {
				let _url: any = window.URL.createObjectURL(blob);
				window.open(_url, "_blank")?.focus();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<div className={styles.shippingReport}>
			<Button
				icon="sys-back-2"
				onClick={() => history.push("/reports")}
				design="Emphasized"
			>
				Atras
			</Button>
			<br />
			<Form>
				<FormGroup titleText="Filtrar	">
					<FormItem label={"Entrada"}>
						{filterType !== FILTER_BY_DATE_RANGE && (
							<ComboBox
								style={{ width: "100%" }}
								onChange={(e) => setFilterValue(e.target.value.toString())}
								value={filterValue}
								disabled={filterType === ""}
							>
								{filterType === FILTER_BY_QUOTATION &&
									quotations.map((quotation: Quotation) => (
										<ComboBoxItem
											key={quotation.quotationid}
											text={quotation.quotationid.toString()}
										/>
									))}
								{filterType === FILTER_BY_CUSTOMER_NAME &&
									customers.map((customer: Customer) => (
										<ComboBoxItem
											key={customer.customerid}
											text={customer.fullname}
										/>
									))}
							</ComboBox>
						)}
						{filterType === FILTER_BY_DATE_RANGE && (
							<DateRangePicker
								style={{ width: "100%" }}
								onChange={(e) => {
									setFilterValue(e.target.value.toString());
								}}
								primaryCalendarType="Gregorian"
							/>
						)}
					</FormItem>
					<FormItem label={"Tipo de filtro"}>
						<ComboBox
							style={{ width: "100%" }}
							onChange={(e) => {
								setFilterType(e.target.value.toString());
								setFilterValue("");
							}}
							value={filterType}
						>
							<ComboBoxItem text={FILTER_BY_QUOTATION} />
							<ComboBoxItem text={FILTER_BY_CUSTOMER_NAME} />
							<ComboBoxItem text={FILTER_BY_DATE_RANGE} />
						</ComboBox>
					</FormItem>
					<FormItem label={""}>
						<Button
							design="Positive"
							style={{ width: "100%" }}
							icon="search"
							onClick={handleFilter}
						>
							Buscar
						</Button>
					</FormItem>
					<FormItem label={""}>
						<CSVLink
							data={shippings}
							filename={`shipping-report-${new Date().toLocaleString("es-GT", {
								timeZone: "America/Guatemala",
							})}`}
							style={{ width: "100%" }}
						>
							<Button icon="excel-attachment" style={{ width: "100%" }}>
								Descargar CSV
							</Button>
						</CSVLink>
					</FormItem>
				</FormGroup>
			</Form>
			<br />
			<FlexBox justifyContent="End">
				{pageNavigator > 1 && (
					<Button
						icon="close-command-field"
						onClick={() => setPageNavigator((prev: any) => prev - 1)}
					/>
				)}
				{pageNumbers.map((number: any) => (
					<Button
						key={number}
						onClick={() => setCurrentPage(number)}
						design={currentPage === number ? "Emphasized" : "Default"}
					>
						{number}
					</Button>
				))}
				{pageAvailable > 5 && (
					<Button
						icon="open-command-field"
						onClick={() => setPageNavigator((prev: any) => prev + 1)}
					/>
				)}
			</FlexBox>
			<br />
			<Table
				columns={
					<>
						<TableColumn>
							<Label>Envio</Label>
						</TableColumn>

						<TableColumn
							demandPopin
							minWidth={1000}
							popinText="Fecha de Creación"
						>
							<Label>Fecha de Creación</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={1000}
							popinText="Fecha de Entrega"
						>
							<Label>Fecha de Entrega</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Proforma">
							<Label>Proforma</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={2000} popinText="Cliente">
							<Label>Cliente</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={2000}
							popinText="Dirección de Entrega"
						>
							<Label>Dirección de Entrega</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Autor">
							<Label>Autor</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Vendedor">
							<Label>Vendedor</Label>
						</TableColumn>
						<TableColumn>
							<Label>Total</Label>
						</TableColumn>
						<TableColumn>
							<Label>Acciones</Label>
						</TableColumn>
					</>
				}
			>
				{currentShppings.map((shipping, i) => (
					<TableRow key={i}>
						<TableCell>
							<Label>{shipping.shippingid}</Label>
						</TableCell>
						<TableCell>
							<Label>{cleanDate(shipping.creationdate).split(" ")[0]}</Label>
						</TableCell>
						<TableCell>
							<Label>{cleanDate(shipping.deliverydate).split(" ")[0]}</Label>
						</TableCell>
						<TableCell>
							<Label>{shipping.quotationid}</Label>
						</TableCell>
						<TableCell>
							<Label>{shipping.customer}</Label>
						</TableCell>
						<TableCell>
							<Label>{shipping.deliveryaddress}</Label>
						</TableCell>
						<TableCell>
							<Label>{shipping.nickname}</Label>
						</TableCell>
						<TableCell>
							<Label>{shipping.seller}</Label>
						</TableCell>
						<TableCell>
							<Label>{parseFloat(shipping.grandtotal).toFixed(2)}</Label>
						</TableCell>
						<TableCell>
							<Button
								icon="open-command-field"
								onClick={() =>
									window
										.open(
											`/documents/shipping/${shipping.shippingid}`,
											"_blank"
										)
										?.focus()
								}
								style={{ marginRight: "5px" }}
							>
								Editar
							</Button>
							<Button
								icon="create"
								onClick={() =>
									viewFile(
										`${API_URL}/external/shipping/${shipping.shippingid}`
									)
								}
								design="Positive"
								style={{ marginRight: "5px" }}
							>
								Ver Envío
							</Button>
						</TableCell>
					</TableRow>
				))}
			</Table>
		</div>
	);
}
