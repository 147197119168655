import { Alert, AlertTitle, Box } from "@mui/material";

interface iProps {
  title: string;
  message: string;
  type: any;
}

const ShowError: React.FC<iProps> = ({ title, message, type }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Alert severity={type} sx={{ margin: "auto", width: "80%" }}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Box>
  );
};

export default ShowError;
