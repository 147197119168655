import styles from "./AdminDashboard.module.css";

import {
	Panel,
	Title,
	Grid,
	Icon,
	AnalyticalCard,
	AnalyticalCardHeader,
	Table,
	TableRow,
	TableCell,
	Label,
	TableColumn,
} from "@ui5/webcomponents-react";

export function AdminDashboard() {
	return (
		<div className={styles.adminDashboard}>
			<Panel headerText="Vista General">
				<Grid style={{ marginTop: "2vh" }}>
					<div data-layout-span="XL6 L6 M6 S12">
						<AnalyticalCard
							header={
								<AnalyticalCardHeader
									currency="Q"
									subtitleText="Total"
									titleText="Proformas"
								/>
							}
							style={{ width: "100%" }}
						>
							<div style={{ textAlign: "center" }}>
								<Title level="H6">2,250</Title>
							</div>
						</AnalyticalCard>
					</div>
					<div data-layout-span="XL6 L6 M6 S12">
						<AnalyticalCard
							header={
								<AnalyticalCardHeader
									currency="Q"
									subtitleText="Total"
									titleText="Facturas"
								/>
							}
							style={{ width: "100%" }}
						>
							<div style={{ textAlign: "center" }}>
								<Title level="H6">0</Title>
							</div>
						</AnalyticalCard>
					</div>
				</Grid>
			</Panel>
			<Panel headerText="Proformas Recientes">
				<Table
					columns={
						<>
							<TableColumn style={{ width: "12rem" }}>
								<Label>Correlativo</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Fecha">
								<Label>Fecha</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Cliente">
								<Label>Cliente</Label>
							</TableColumn>
							<TableColumn>
								<Label>Total</Label>
							</TableColumn>
							<TableColumn>
								<Label></Label>
							</TableColumn>
						</>
					}
					onLoadMore={function noRefCheck() {}}
					onPopinChange={function noRefCheck() {}}
					onRowClick={function noRefCheck() {}}
					onSelectionChange={function noRefCheck() {}}
				>
					<TableRow>
						<TableCell>
							<Label>ISFP-1001</Label>
						</TableCell>
						<TableCell>
							<Label>20/9/2021</Label>
						</TableCell>
						<TableCell>
							<Label>Pruebas S.A</Label>
						</TableCell>
						<TableCell>
							<Label>Q500.00</Label>
						</TableCell>
						<TableCell>
							<Label>{<Icon name="action" />}</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISFP-1002</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021</Label>
						</TableCell>
						<TableCell>
							<Label>SinNIT S.A.</Label>
						</TableCell>
						<TableCell>
							<Label>Q400.00</Label>
						</TableCell>
						<TableCell>
							<Label>{<Icon name="action" />}</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISFP-1003</Label>
						</TableCell>
						<TableCell>
							<Label>15/9/2021</Label>
						</TableCell>
						<TableCell>
							<Label>Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label>Q350.00</Label>
						</TableCell>
						<TableCell>
							<Label>{<Icon name="action" />}</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISFP-1004</Label>
						</TableCell>
						<TableCell>
							<Label>10/9/2021</Label>
						</TableCell>
						<TableCell>
							<Label>InfileTest</Label>
						</TableCell>
						<TableCell>
							<Label>Q1,000.00</Label>
						</TableCell>
						<TableCell>
							<Label>{<Icon name="action" />}</Label>
						</TableCell>
					</TableRow>
				</Table>
			</Panel>
			<Panel headerText="Envios Recientes">
				<Table
					columns={
						<>
							<TableColumn style={{ width: "12rem" }}>
								<Label>Correlativo</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Fecha">
								<Label>Fecha y Hora</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Cliente">
								<Label>Cliente</Label>
							</TableColumn>
							<TableColumn>
								<Label>Proforma</Label>
							</TableColumn>
							<TableColumn>
								<Label></Label>
							</TableColumn>
						</>
					}
					onLoadMore={function noRefCheck() {}}
					onPopinChange={function noRefCheck() {}}
					onRowClick={function noRefCheck() {}}
					onSelectionChange={function noRefCheck() {}}
				>
					<TableRow>
						<TableCell>
							<Label>ISEN-1004</Label>
						</TableCell>
						<TableCell>
							<Label>21/9/2021 20:50</Label>
						</TableCell>
						<TableCell>
							<Label>Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label>ISFP-1003</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a href="./docs/ISEN-1004.pdf" target="_blank">
									{<Icon name="action" />}{" "}
								</a>
								<a href="./docs/ISEN-1004.pdf" download>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISEN-1003</Label>
						</TableCell>
						<TableCell>
							<Label>20/9/2021 10:00</Label>
						</TableCell>
						<TableCell>
							<Label>Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label>ISFP-1003</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a href="./docs/ISEN-1004.pdf" target="_blank">
									{<Icon name="action" />}{" "}
								</a>
								<a href="./docs/ISEN-1004.pdf" download>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISEN-1002</Label>
						</TableCell>
						<TableCell>
							<Label>19/9/2021 11:30</Label>
						</TableCell>
						<TableCell>
							<Label>Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label>ISFP-1003</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a href="./docs/ISEN-1004.pdf" target="_blank">
									{<Icon name="action" />}{" "}
								</a>
								<a href="./docs/ISEN-1004.pdf" download>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>ISEN-1001</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021 23:30</Label>
						</TableCell>
						<TableCell>
							<Label>Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label>ISFP-1003</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a href="./docs/ISEN-1004.pdf" target="_blank">
									{<Icon name="action" />}{" "}
								</a>
								<a href="./docs/ISEN-1004.pdf" download>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
				</Table>
			</Panel>
			<Panel headerText="DTE Certificados, Recientes">
				<Table
					columns={
						<>
							<TableColumn style={{ width: "12rem" }}>
								<Label>UUID</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Tipo">
								<Label>Tipo</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Fecha">
								<Label>Fecha</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={600} popinText="Cliente">
								<Label>Cliente</Label>
							</TableColumn>
							<TableColumn>
								<Label>Total</Label>
							</TableColumn>
							<TableColumn>
								<Label></Label>
							</TableColumn>
						</>
					}
					onLoadMore={function noRefCheck() {}}
					onPopinChange={function noRefCheck() {}}
					onRowClick={function noRefCheck() {}}
					onSelectionChange={function noRefCheck() {}}
				>
					<TableRow>
						<TableCell>
							<Label>6D55B947-7210-4CB3-8653-BEE971794E90</Label>
						</TableCell>
						<TableCell>
							<Label> FCAM</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021 23:50:04</Label>
						</TableCell>
						<TableCell>
							<Label> Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label> Q350.00</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									target="_blank"
								>
									{<Icon name="action" />}{" "}
								</a>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									download
								>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>6D358AF9-75F2-4255-BC40-3A702BF8D469</Label>
						</TableCell>
						<TableCell>
							<Label> FCAM</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021 23:30:01</Label>
						</TableCell>
						<TableCell>
							<Label> Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label> Q350.00</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									target="_blank"
								>
									{<Icon name="action" />}{" "}
								</a>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									download
								>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>6D358AF9-75F2-4255-BC40-3A702BF8D469</Label>
						</TableCell>
						<TableCell>
							<Label> FCAM</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021 23:15:01</Label>
						</TableCell>
						<TableCell>
							<Label> Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label> Q350.00</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									target="_blank"
								>
									{<Icon name="action" />}{" "}
								</a>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									download
								>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<Label>6D358AF7-75F2-4255-BC40-3A702BF8D469</Label>
						</TableCell>
						<TableCell>
							<Label> FCAM</Label>
						</TableCell>
						<TableCell>
							<Label>18/9/2021 23:00:06</Label>
						</TableCell>
						<TableCell>
							<Label> Otoniel Salguero Alvarez</Label>
						</TableCell>
						<TableCell>
							<Label> Q350.00</Label>
						</TableCell>
						<TableCell>
							<Label>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									target="_blank"
								>
									{<Icon name="action" />}{" "}
								</a>
								<a
									href="./docs/6D358AF7-75F2-4255-BC40-3A702BF8D469.pdf"
									download
								>
									{<Icon name="download" />}{" "}
								</a>{" "}
							</Label>
						</TableCell>
					</TableRow>
				</Table>
			</Panel>
		</div>
	);
}
