export interface ProductDTO {
	productId: number;
	code: string;
	quickOverview: string;
	productTypeId: number;
	unitPrice: number;
	discount: number;
	billingCodeId: number;
	detailedDescription: string;
}

export const productInitialState: ProductDTO = {
	productId: 0,
	code: "",
	quickOverview: "",
	productTypeId: 0,
	unitPrice: 0.0,
	discount: 0.0,
	billingCodeId: 0,
	detailedDescription: "",
};
