import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import debounce from "lodash.debounce";
import FilterField from "../../models/filterField";
import FilterInput from "../filter-input/FilterInput";

interface Props {
  fields: FilterField[];
  onChangeFilter: (value: any) => void;
}

const FilterBillTable: React.FC<Props> = ({ fields, onChangeFilter }) => {
  const [innerFilter, setInnerFilter] = useState({});
  const onFilterChange = (e: any) => {
    const deboundeFilter = debounce(() => {
      setInnerFilter({ ...innerFilter, [e.target.id]: e.target.value });
    }, 500);

    deboundeFilter();
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onChangeFilter(innerFilter);
    }
  };

  const clearFilter = () => {
    onChangeFilter({});
    setInnerFilter({});
    fields.forEach((field) => {
      const inputEl: any = document.getElementById(field.id);
      if (inputEl) {
        inputEl.value = "";
      }
    });
  };

  return (
    <Box sx={{ height: "100vh", width: "100%", p: 1.5, background: "" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <p
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          Filtros
        </p>
      </Box>
      {fields.map((field) => (
        <FilterInput
          key={field.id}
          field={field}
          onFilterChange={onFilterChange}
          onKeyDown={onKeyDown}
        />
      ))}
      <Button
        variant="outlined"
        color="error"
        sx={{ width: "100%", marginTop: "1rem" }}
        onClick={clearFilter}
      >
        Limpiar
      </Button>
      <Button
        variant="outlined"
        sx={{ width: "100%", marginTop: "1rem" }}
        onClick={() => onChangeFilter(innerFilter)}
      >
        Aplicar Filtros
      </Button>
    </Box>
  );
};

export default FilterBillTable;
