import styles from "./User.module.css";
import {
	Button,
	ComboBox,
	ComboBoxItem,
	Dialog,
	Form,
	FormItem,
	Icon,
	Input,
} from "@ui5/webcomponents-react";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { _User, _userInitialValue } from "../../models/_user.model";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { _UserType } from "../../models/_usertype.model";
import { Seller } from "../../models/seller.model";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";

const userInitialState: _User = {
	_userid: 0,
	_usertypeid: 0,
	nickname: "",
	password: "",
	sellerid: 0,
};

export function User() {
	const [nickname, setNickname] = useState<string>("");
	const [users, setUsers] = useState<_User[]>([]);
	const [user, setUser] = useState<_User>(userInitialState);
	const [userTypes, setUserTypes] = useState<_UserType[]>([]);
	const [sellers, setSellers] = useState<Seller[]>([]);

	const [cookie] = useCookies(["access"]);
	const apiAccess = {
		headers: { Authorization: `Bearer ${cookie["access"]}` },
	};

	const showMessage = useRef<Ui5DialogDomRef>(null);
	const [messageTitle, setMessageTitle] = useState<string>("");
	const [messageContent, setMessageContent] = useState<string>("");

	const [userCookie] = useCookies(["user"]);

	useEffect(() => {
		const apiAccess = {
			headers: { Authorization: `Bearer ${cookie["access"]}` },
		};
		axios
			.get(`${API_URL}/users`, apiAccess)
			.then((response: any) => setUsers(response.data));
		axios
			.get(`${API_URL}/crud/_usertype`, apiAccess)
			.then((response: any) => setUserTypes(response.data));
		axios
			.get(`${API_URL}/crud/seller`, apiAccess)
			.then((response: any) => setSellers(response.data));
	}, [cookie]);

	const findUser = async () => {
		if (nickname === "") {
			setUser(_userInitialValue);
			return;
		}
		try {
			let user: _User | undefined = users.find(
				(user: _User) => user.nickname.toString() === nickname.toString()
			);
			if (user !== undefined) {
				const response = await axios.get(
					`${API_URL}/users/${user._userid}`,
					apiAccess
				);
				setUser({ ...response.data[0], password: "" });
			}
		} catch (error) {}
	};

	/**
	 * Displays a dialog message
	 * @param title Message title
	 * @param content Message content
	 */
	const displayMessage = (title: string, content: string) => {
		setMessageTitle(title);
		setMessageContent(content);
		showMessage.current?.show();
	};

	/**
	 * Gets the current user type
	 * @returns Current users type
	 */
	const getUserType = (): string => {
		if (user._usertypeid === 0) return "";
		for (let i = 0; i < userTypes.length; i++) {
			const userType = userTypes[i];
			if (userType._usertypeid === user._usertypeid) {
				return userType.name;
			}
		}
		return "";
	};

	/**
	 * Gets the current user seller
	 * @returns Current users seller
	 */
	const getUserSeller = (): string => {
		if (user.sellerid === 0) return "";
		for (let i = 0; i < sellers.length; i++) {
			const seller = sellers[i];
			if (seller.sellerid === user.sellerid) {
				return seller.fullname;
			}
		}
		return "";
	};

	/**
	 * Verifies the user data integrity
	 * @returns True if the user data is valid
	 */
	const verifyUserIntegrity = (): boolean => {
		if (user.nickname === "") {
			displayMessage("Error", "Ingresa el alias del usuario");
			return false;
		}
		if (user.password === "") {
			displayMessage("Error", "Ingresa la contraseña del usuario");
			return false;
		}
		if (user._usertypeid === 0) {
			displayMessage("Error", "Ingresa el tipo de usuario");
			return false;
		}
		if (user.sellerid === 0) {
			displayMessage("Error", "Ingresa el vendedor asignado al usuario");
			return false;
		}
		return true;
	};

	/**
	 * Saves the current user
	 * @param user Users data
	 * @returns Users save data
	 */
	const saveInternalUser = async (user: _User) => {
		try {
			const response = await axios.post(`${API_URL}/users`, user, apiAccess);
			return [response.data[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Updates the current user
	 * @param user Users data
	 * @returns Users update data
	 */
	const updateInternalUser = async (user: _User) => {
		try {
			const response = await axios.put(`${API_URL}/users`, user, apiAccess);
			return [response.data, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Save user orchestrator
	 */
	const saveUser = async () => {
		if (!verifyUserIntegrity()) return;
		if (user._userid === 0) {
			const [saveUserData, saveUserError] = await saveInternalUser(user);
			if (saveUserError) {
				displayMessage("Error al crear usuario", "Verifica los datos");
				return;
			}
			setUser({ password: user.password, ...saveUserData });
			displayMessage(
				"Usuario creado",
				`Usuario #${saveUserData._userid} creado`
			);
			axios
				.get(`${API_URL}/users`, apiAccess)
				.then((response: any) => setUsers(response.data));
		}
		if (user._userid !== 0) {
			const [, updateUserError] = await updateInternalUser(user);
			if (updateUserError) {
				displayMessage("Error al actualizar usuario", "Verificar los datos");
				return;
			}
			displayMessage(
				"Usuario actualizado",
				`Usuario #${user._userid} actualizado`
			);
		}
	};

	/**
	 * Handles comboboxes changes
	 * @param value Combobox value
	 * @param field Field to be affected
	 */
	const handleComboBoxChange = (value: string, field: string) => {
		switch (field) {
			case "_usertypeid":
				for (let i = 0; i < userTypes.length; i++) {
					const userType = userTypes[i];
					if (userType.name.toString() === value.toString()) {
						setUser({ ...user, _usertypeid: userType._usertypeid });
						return;
					}
				}
				setUser({ ...user, _usertypeid: 0 });
				break;
			case "sellerid":
				for (let u = 0; u < sellers.length; u++) {
					const seller = sellers[u];
					if (seller.fullname.toString() === value.toString()) {
						setUser({ ...user, sellerid: seller.sellerid });
						return;
					}
				}
				setUser({ ...user, sellerid: 0 });
				break;
		}
	};

	return (
		<div className={styles.user}>
			<Form
				titleText="Búsqueda"
				columnsXL={2}
				columnsL={2}
				columnsM={1}
				columnsS={1}
			>
				<FormItem label={"Alias"}>
					<ComboBox
						icon={<Icon name="employee" />}
						onChange={(e) => {
							setNickname(e.target.value.toString());
						}}
						className={styles.input}
					>
						{users.map((user: _User) => (
							<ComboBoxItem text={user.nickname} key={user._userid} />
						))}
					</ComboBox>
				</FormItem>
				<FormItem label={""}>
					<Button icon="search" onClick={findUser} className={styles.button}>
						Buscar
					</Button>
				</FormItem>
			</Form>
			<br />
			<Form
				titleText="Información del Usuario"
				columnsXL={1}
				columnsL={1}
				columnsM={1}
				columnsS={1}
			>
				<FormItem label={"Alias"}>
					<Input
						icon={<Icon name="employee" />}
						onInput={(e) =>
							setUser({ ...user, nickname: e.target.value.toString() })
						}
						className={styles.input}
						value={user.nickname}
						style={{
							border: user.nickname === "" ? "2px solid red" : "",
						}}
						type="Text"
					/>
				</FormItem>
				<FormItem label={"Contraseña"}>
					<Input
						icon={<Icon name="key" />}
						onInput={(e) =>
							setUser({ ...user, password: e.target.value.toString() })
						}
						className={styles.input}
						style={{
							border: user.password === "" ? "2px solid red" : "",
						}}
						type="Password"
						value={user.password}
					/>
				</FormItem>
				<FormItem label={"Tipo de Usuario"}>
					<ComboBox
						icon={<Icon name="manager" />}
						className={styles.input}
						style={{ border: user._usertypeid === 0 ? "2px solid red" : "" }}
						value={getUserType()}
						onChange={(e) =>
							handleComboBoxChange(e.target.value.toString(), "_usertypeid")
						}
					>
						{userTypes.map((userType: _UserType) => (
							<ComboBoxItem text={userType.name} key={userType._usertypeid} />
						))}
					</ComboBox>
				</FormItem>
				<FormItem label={"Vendedor"}>
					<ComboBox
						icon={<Icon name="supplier" />}
						className={styles.input}
						style={{ border: user.sellerid === 0 ? "2px solid red" : "" }}
						value={getUserSeller()}
						onChange={(e) =>
							handleComboBoxChange(e.target.value.toString(), "sellerid")
						}
					>
						{sellers.map((seller: Seller) => (
							<ComboBoxItem text={seller.fullname} key={seller.sellerid} />
						))}
					</ComboBox>
				</FormItem>
				<FormItem label={""}>
					<Button
						icon="add"
						onClick={saveUser}
						className={styles.button}
						design="Emphasized"
						disabled={userCookie.user.toString() !== "1"}
					>
						{user._userid === 0 ? "Crear Usuario" : "Modificar Usuario"}
					</Button>
				</FormItem>
			</Form>
			<Dialog
				ref={showMessage}
				footer={
					<div style={{ padding: "5px" }}>
						<Button
							onClick={() => showMessage.current?.close()}
							design="Emphasized"
						>
							Cerrar
						</Button>
					</div>
				}
				headerText={messageTitle}
			>
				<div style={{ padding: "5px" }}>{messageContent}</div>
			</Dialog>
		</div>
	);
}

export default User;
