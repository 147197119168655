import styles from "./CustomerReport.module.css";

import {
	Button,
	ComboBox,
	ComboBoxItem,
	FlexBox,
	Form,
	FormGroup,
	FormItem,
	Input,
	Label,
	Table,
	TableCell,
	TableColumn,
	TableRow,
} from "@ui5/webcomponents-react";
import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { useCookies } from "react-cookie";
import { CSVLink } from "react-csv";

export function CustomerReport() {
	const [cookie] = useCookies(["access"]);

	/**
	 * Customer report global props
	 */
	const history = useHistory();
	const [customers, setCustomers] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageNavigator, setPageNavigator] = useState<number>(1);
	const [filterType, setFilterType] = useState<string>("");
	const [filterValue, setFilterValue] = useState<string>("");
	const apiAccess = {
		headers: { Authorization: `Bearer ${cookie["access"]}` },
	};
	const ITEMS_PER_PAGE: number = 50;

	/**
	 * Gets customer report master data
	 */
	useEffect(() => {
		const apiAccess = {
			headers: { Authorization: `Bearer ${cookie["access"]}` },
		};
		axios
			.post(`${API_URL}/query/customer-report`, {}, apiAccess)
			.then((response: any) => {
				setCustomers(response.data.rows);
			});
	}, [cookie]);

	/**
	 * Pagination
	 */
	const indexOfLastItem: number = currentPage * ITEMS_PER_PAGE;
	const indexOfFirtsItem: number = indexOfLastItem - ITEMS_PER_PAGE;
	const currentCustomers: any[] = customers.slice(
		indexOfFirtsItem,
		indexOfLastItem
	);
	const pageNumber: number = Math.ceil(customers.length / ITEMS_PER_PAGE);
	const pageAvailable: number = pageNumber - pageNavigator;
	const pageNumbers = [];
	for (
		let i = pageNavigator;
		i <= pageNavigator + (pageAvailable < 5 ? pageAvailable : 5);
		i++
	) {
		pageNumbers.push(i);
	}

	/**
	 * Filter constants
	 */
	const FILTER_BY_NIT: string = "Número de NIT";
	const FILTER_BY_CUSTOMER_NAME: string = "Nombre de cliente";

	/**
	 * Handles customer filter
	 */
	const handleFilter = async () => {
		switch (filterType) {
			case FILTER_BY_NIT:
				try {
					const response = await axios.post(
						`${API_URL}/query/customer-report-nit`,
						{ filterValue: filterValue },
						apiAccess
					);
					setCustomers(response.data.rows);
				} catch (error) {}
				break;
			case FILTER_BY_CUSTOMER_NAME:
				try {
					const response = await axios.post(
						`${API_URL}/query/customer-report-fullname`,
						{ filterValue: `%${filterValue}%` },
						apiAccess
					);
					setCustomers(response.data.rows);
				} catch (error) {}
				break;
		}
	};

	return (
		<div className={styles.customerReport}>
			<Button
				icon="sys-back-2"
				onClick={() => history.push("/reports")}
				design="Emphasized"
			>
				Atras
			</Button>
			<br />
			<Form>
				<FormGroup titleText="Filtrar	">
					<FormItem label={"Entrada"}>
						<Input
							type="Text"
							style={{ width: "100%" }}
							onInput={(e) => setFilterValue(e.target.value.toString())}
							disabled={filterType === ""}
						/>
					</FormItem>
					<FormItem label={"Tipo de filtro"}>
						<ComboBox
							style={{ width: "100%" }}
							onChange={(e) => {
								setFilterType(e.target.value.toString());
								setFilterValue("");
							}}
							value={filterType}
						>
							<ComboBoxItem text={FILTER_BY_NIT} />
							<ComboBoxItem text={FILTER_BY_CUSTOMER_NAME} />
						</ComboBox>
					</FormItem>
					<FormItem label={""}>
						<Button
							design="Positive"
							style={{ width: "100%" }}
							icon="search"
							onClick={handleFilter}
						>
							Buscar
						</Button>
					</FormItem>
					<FormItem label={""}>
						<CSVLink
							data={customers}
							filename={`customer-report-${new Date().toLocaleString("es-GT", {
								timeZone: "America/Guatemala",
							})}`}
							style={{ width: "100%" }}
						>
							<Button icon="excel-attachment" style={{ width: "100%" }}>
								Descargar CSV
							</Button>
						</CSVLink>
					</FormItem>
				</FormGroup>
			</Form>
			<br />
			<FlexBox justifyContent="End">
				{pageNavigator > 1 && (
					<Button
						icon="close-command-field"
						onClick={() => setPageNavigator((prev: any) => prev - 1)}
					/>
				)}
				{pageNumbers.map((number: any) => (
					<Button
						key={number}
						onClick={() => setCurrentPage(number)}
						design={currentPage === number ? "Emphasized" : "Default"}
					>
						{number}
					</Button>
				))}
				{pageAvailable > 5 && (
					<Button
						icon="open-command-field"
						onClick={() => setPageNavigator((prev: any) => prev + 1)}
					/>
				)}
			</FlexBox>
			<br />
			<Table
				columns={
					<>
						<TableColumn>
							<Label>NIT</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={2000} popinText="Nombre">
							<Label>Nombre</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Tipo">
							<Label>Tipo</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={2000} popinText="Dirección">
							<Label>Dirección</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={2000}
							popinText="Dirección de Entrega"
						>
							<Label>Dirección de Entrega</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Teléfono">
							<Label>Teléfono</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={2000} popinText="Correo">
							<Label>Correo</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={1000}
							popinText="Días de Crédito"
						>
							<Label>Días de Crédito</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={1000}
							popinText="Límite de Crédito"
						>
							<Label>Límite de Crédito</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={2000} popinText="Notas">
							<Label>Notas</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={2000}
							popinText="Venta en Doláres"
						>
							<Label>Venta en Doláres</Label>
						</TableColumn>
						<TableColumn
							demandPopin
							minWidth={1000}
							popinText="Cliente Exterior"
						>
							<Label>Cliente Exterior</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={1000} popinText="Retiene IVA">
							<Label>Retiene IVA</Label>
						</TableColumn>
					</>
				}
			>
				{currentCustomers.map((customer, i) => (
					<TableRow key={i}>
						<TableCell>
							<Label>{customer.nit}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.fullname}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.type}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.address}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.deliveryaddress}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.phone}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.email}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.days}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.limitcredit}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.about}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.saleindollars}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.foreigncustomer}</Label>
						</TableCell>
						<TableCell>
							<Label>{customer.withholdvat}</Label>
						</TableCell>
					</TableRow>
				))}
			</Table>
		</div>
	);
}
