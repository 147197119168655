import React from "react";
import { TextField } from "@mui/material";
import FilterField from "../../models/filterField";

interface Props {
  field: FilterField;
  onFilterChange: (value: any) => void;
  onKeyDown: (value: any) => void;
}

const FilterInput: React.FC<Props> = ({ field, onFilterChange, onKeyDown }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}
    >
      <label htmlFor={field.id} style={{ fontSize: "1.5rem" }}>
        {field.label}
      </label>
      <TextField
        name={field.name}
        type={field.type}
        id={field.id}
        placeholder={field.placeholder}
        size="small"
        onChange={onFilterChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default FilterInput;
