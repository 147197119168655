import { Box, MenuItem, TextField, Button } from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LastPageIcon from "@mui/icons-material/LastPage";

interface Props {
  pageIndex: number;
  pageSize: number;
  setPageSize: (value: number) => void;
  setPageIndex: (value: number) => void;
}

const PAGE_SIZE_OPTIONS = [10, 20, 30, 40];

const Pagination: React.FC<Props> = ({
  pageIndex,
  setPageIndex,
  pageSize,
  setPageSize,
}) => {
  const goInit = () => {
    setPageIndex(0);
  }


  const goBack = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    } else {
      setPageIndex(0);
    }
  };

  const next = () => {
    setPageIndex(pageIndex + 1);
  };

  const onPageSizeChange = (e: any) => {
    setPageSize(+e.target.value);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <span>Items por Página</span>
        <TextField
          size="small"
          defaultValue={pageSize}
          onChange={onPageSizeChange}
          select
        >
          {PAGE_SIZE_OPTIONS.map((element) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </TextField>
        <Button onClick={goInit}>
          <FirstPageIcon />
        </Button>
        <Button sx={{ transform: "rotate(180deg)" }} onClick={goBack}>
          <NavigateNextIcon />
        </Button>
          <span>Página {pageIndex}</span>
        <Button onClick={next}>
          <NavigateNextIcon />
        </Button>
        <Button>
          <LastPageIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Pagination;
