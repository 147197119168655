import { Box, Skeleton, Stack } from "@mui/material";

const LoadingTable = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "100%", margin: "auto", marginTop: "5rem" }}>
        <Stack spacing={1}>
          <Skeleton variant="text" height={100} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="rectangular" height={40} />
        </Stack>
      </Box>
    </Box>
  );
};

export default LoadingTable;
