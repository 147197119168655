import { TableCell, TableHead, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

interface propsHeaderTable {
  headerNames: string[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#92a0ad",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const HeaderTable: React.FC<propsHeaderTable> = ({ headerNames }) => {
  return (
    <TableHead sx={{ color: "#69a0d3" }}>
      <TableRow sx={{ color: "#aed6f1" }}>
        {headerNames.map((e: string, index) => (
          <StyledTableCell align="center" key={index}>
            {e}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
