import { HeaderTable } from "../header-table/HeaderTable";
import { Table, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { Box } from "@mui/system";
import { SpanTable } from "../span-table/SpanTable";

interface dynamicTableProps {
  headers: string[];
  tableContent: any[];
  tableActions?: any[];
  spanTableData?: any[];
}

export const DynamicTable: React.FC<dynamicTableProps> = ({
  headers,
  tableContent,
  tableActions = [],
  spanTableData = [],
}) => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <Table>
        <HeaderTable headerNames={headers} />
        <TableBody>
          {tableContent.map((item: any, index: number) => (
            <TableRow key={index}>
              {Object.values(item).map((value: any, indexI: number) => (
                <TableCell align="center" key={indexI}>
                  {value}
                </TableCell>
              ))}

              {tableActions.map((value: any, i: number) => (
                <TableCell key={i} align="center">
                  <Button
                    {...value.props}
                    onClick={() => value.callback(index)}
                  >
                    {value.props.text}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          ))}
          {spanTableData.length > 0 && <SpanTable data={spanTableData} />}
        </TableBody>
      </Table>
    </Box>
  );
};
