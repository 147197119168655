import styles from "./Header.module.css";

import { ShellBar } from "@ui5/webcomponents-react";
import { useEffect, useState } from "react";
import { API_URL } from "../../app/constants";
import axios from "axios";
import { useCookies } from "react-cookie";
import MainLogo from "../../assets/main-logo.jpg";

export const Header = () => {
  const [cookie] = useCookies(["access"]);
  const [todayExchange, setTodayExchange] = useState("");

  useEffect(() => {
    const apiAccess = {
      headers: { Authorization: `Bearer ${cookie["access"]}` },
    };
    if (todayExchange === "") {
      if (cookie["access"] !== undefined) {
        axios
          .get(`${API_URL}/external/exchange`, apiAccess)
          .then((exchange: any) => {
            setTodayExchange(exchange.data.ratio);
          });
      }
    }
  }, [todayExchange, cookie]);

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result
      .toLocaleString("es-GT", { timeZone: "America/Guatemala" })
      .split(" ")[0];
  };

  return (
    <ShellBar
      className={styles.header}
      logo={
        <img
          alt="Branch Logo"
          src={MainLogo}
          style={{ backgroundColor: "white" }}
        />
      }
      notificationsCount="1"
      onCoPilotClick={function noRefCheck() {}}
      onLogoClick={function noRefCheck() {}}
      onMenuItemClick={function noRefCheck() {}}
      onNotificationsClick={function noRefCheck() {}}
      onProductSwitchClick={function noRefCheck() {}}
      onProfileClick={function noRefCheck() {}}
      primaryTitle={
        "Ciudad de Guatemala, " + cleanDate(new Date()) + ", v3.1.1"
      }
      secondaryTitle={`Cambio: 1.00 USD = ${todayExchange} QTZ`}
    />
  );
};
