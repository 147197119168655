import "./App.css";
import { Switch, Route } from "react-router-dom";
import { Header } from "./features/header/Header";
import { SideNavigationBar } from "./features/side-navigation-bar/SideNavigationBar";
import { Grid } from "@ui5/webcomponents-react";

import { ExportInvoice } from "./features/export-invoice/ExportInvoice";
import { Login } from "./features/login/Login";
import { AdminDashboard } from "./features/admin-dashboard/AdminDashboard";
import { Documents } from "./features/documents/Documents";
import { Customer } from "./features/customer/Customer";
import { Product } from "./features/product/Product";
import { Shipping } from "./features/shipping/Shipping";
import { Reports } from "./features/reports/Reports";
import { ShippingReport } from "./features/shipping-report/ShippingReport";
import { ProductReport } from "./features/product-report/ProductReport";
import { CustomerReport } from "./features/customer-report/CustomerReport";

import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { LogReport } from "./features/log-report/LogReport";
import { Quotation } from "./features/quotation/Quotation";
import User from "./features/user/User";
import QuotationNewReport from "./components/quotation-report/QuotationNewReport";
import BillNewReport from "./components/bill-report/BillNewReport";
import LoadBills from "./features/load-bills/loadBills";

function App() {
  const [cookie] = useCookies(["access"]);
  const [userCookie] = useCookies(["user"]);
  const [nicknameCookie] = useCookies(["nickname"]);
  const history = useHistory();

  useEffect(() => {
    if (
      cookie["access"] === undefined ||
      userCookie["user"] === undefined ||
      nicknameCookie["nickname"] === undefined
    ) {
      history.push("/login");
    }
  }, [cookie, history, userCookie, nicknameCookie]);

  return (
    <div>
      <Header />
      <Grid>
        {cookie["access"] !== undefined &&
          userCookie["user"] !== undefined &&
          nicknameCookie["nickname"] !== undefined && (
            <div data-layout-span="XL2 L2 M3 S12">
              <SideNavigationBar />
            </div>
          )}
        <div
          data-layout-span={
            cookie["access"] !== undefined &&
            userCookie["user"] !== undefined &&
            nicknameCookie["nickname"] !== undefined
              ? "XL10 L10 M9 S12"
              : "XL12 L12 M12 S12"
          }
        >
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            {cookie["access"] !== undefined &&
              userCookie["user"] !== undefined &&
              nicknameCookie["nickname"] !== undefined && [
                <Route exact path="/" key={1}>
                  <Documents />
                </Route>,
                <Route exact path="/exportInvoice" key={2}>
                  <ExportInvoice />
                </Route>,
                <Route exact path="/adminDashboard" key={3}>
                  <AdminDashboard />
                </Route>,
                <Route exact path="/documents" key={4}>
                  <Documents />
                </Route>,
                <Route exact path="/customer" key={5}>
                  <Customer />
                </Route>,
                <Route exact path="/product" key={6}>
                  <Product />
                </Route>,
                <Route exact path="/documents/shipping/:shippingId" key={7}>
                  <Shipping />
                </Route>,
                <Route exact path="/reports" key={8}>
                  <Reports />
                </Route>,
                <Route exact path="/shippingReport" key={9}>
                  <ShippingReport />
                </Route>,
                <Route exact path="/productReport" key={10}>
                  <ProductReport />
                </Route>,
                <Route exact path="/customerReport" key={11}>
                  <CustomerReport />
                </Route>,
                <Route exact path="/quotationReport" key={12}>
                  <QuotationNewReport />
                  {/* <QuotationReport /> */}
                </Route>,
                <Route exact path="/billReport" key={13}>
                  {/* <BillReport /> */}
                  <BillNewReport />
                </Route>,
                userCookie && userCookie.user.toString() === "1" && (
                  <Route exact path="/logReport" key={14}>
                    <LogReport />
                  </Route>
                ),
                <Route exact path="/documents/quotation/:quotationId" key={15}>
                  <Quotation />
                </Route>,
                userCookie && userCookie.user.toString() === "1" && (
                  <Route exact path="/user" key={16}>
                    <User />
                  </Route>
                ),
                userCookie && userCookie.user.toString() === "1" && (
                  <Route exact path="/loadBills" key={16}>
                    <LoadBills />
                  </Route>
                ),
              ]}
          </Switch>
        </div>
      </Grid>
    </div>
  );
}

export default App;
