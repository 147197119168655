import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ISnackBar {
  message: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  severity: any;
}

export const SnackBar: React.FC<ISnackBar> = ({
  message,
  open,
  setOpen,
  severity,
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} onClose={handleClose} autoHideDuration={10000}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          <AlertTitle>
            {severity ? severity.toString() : "Mensaje del Sistema"}
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
