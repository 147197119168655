export interface Customer {
  customerid: number;
  nit: string;
  fullname: string;
  address: string;
  phone: string;
  internalcode: string;
  email: string;
  customertypeid: number;
  admissiondate: string;
  sellerid: number;
  deliveryaddress: string;
  acceptcheck: boolean;
  saleindollars: boolean;
  foreigncustomer: boolean;
  withholdvat: boolean;
  limitcredit: number;
  maximumdiscount: number;
  creditdaysid: number;
  about: string;
  dpi: string;
  passportid: string;
}

export const customerInitialValue: Customer = {
  customerid: 0,
  nit: "",
  fullname: "",
  address: "",
  phone: "",
  internalcode: "",
  email: "",
  customertypeid: 0,
  admissiondate: "",
  sellerid: 0,
  deliveryaddress: "",
  acceptcheck: false,
  saleindollars: false,
  foreigncustomer: false,
  withholdvat: false,
  limitcredit: 0,
  maximumdiscount: 0,
  creditdaysid: 0,
  about: "",
  dpi: "",
  passportid: "",
};
