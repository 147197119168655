export interface ShippingDTO {
	shippingId: number;
	quotationId: number;
	creationDate?: string;
	deliveryDate: string;
	shippingRemarks: string;
	totalWithVat: number;
	totalWithoutVat: number;
	grandTotal: number;
	nickname: string;
}

export const shippingInitialState: ShippingDTO = {
	shippingId: 0,
	quotationId: 0,
	creationDate: "",
	deliveryDate: "",
	shippingRemarks: "",
	totalWithVat: 0.0,
	totalWithoutVat: 0.0,
	grandTotal: 0,
	nickname: "",
};
