import { Box, Button, IconButton } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";

const getFields = (setQuotationId: any) => {
  return [
    {
      header: "Opciones",
      Cell: ({ row }: any) => (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>
          <IconButton
            size="small"
            onClick={() =>
              window.open(`/documents/quotation/${row.original.quotationid}`)
            }
          >
            <EditIcon color="primary" />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => setQuotationId(row.original.quotationid)}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      header: "Numero de Proforma",
      id: "quotationid",
      accessorKey: "quotationid",
    },
    {
      header: "Orden de Produccion",
      id: "productionorder",
      accessorKey: "productionorder",
    },
    {
      header: "Orden de Compra",
      id: "purchaseorder",
      accessorKey: "purchaseorder",
    },
    {
      header: "Cliente",
      id: "customer",
      accessorKey: "customer",
    },
    {
      header: "Total",
      id: "grandtotal",
      accessorKey: "grandtotal",
    },
    {
      header: "Estado",
      id: "state",
      accessorKey: "state",
    },
    {
      header: "Fecha de Facturacion",
      id: "billingdate",
      accessorKey: "billingdate",
      Cell: ({ row }: any) =>
        `${new Date(row.original.billingdate).toLocaleString()}`,
    },
    {
      header: "Tipo de Proforma",
      id: "type",
      accessorKey: "type",
    },
    {
      header: "Moneda",
      id: "currency",
      accessorKey: "currency",
    },
    {
      header: "Descripcion",
      id: "description",
      accessorKey: "description",
    },
    {
      header: "Envio",
      id: "shippings",
      accessorKey: "shippings",
    },
    {
      header: "Vendedor",
      id: "seller",
      accessorKey: "seller",
    },
    {
      header: "Usuario",
      id: "nickname",
      accessorKey: "nickname",
    },
  ];
};

export default getFields;
