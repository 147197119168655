import styles from "./LogReport.module.css";

import {
	Button,
	ComboBox,
	ComboBoxItem,
	FlexBox,
	Form,
	FormGroup,
	FormItem,
	Label,
	Table,
	TableCell,
	TableColumn,
	TableRow,
	Loader,
	Dialog,
} from "@ui5/webcomponents-react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../app/constants";
import { useCookies } from "react-cookie";
import { CSVLink } from "react-csv";
import { _User } from "../../models/_user.model";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";

interface Log {
	_user: string;
	body: string;
	uri: string;
	time: string;
}

export function LogReport() {
	/**
	 * Bill report global props
	 */
	const history = useHistory();
	const [logs, setLogs] = useState<Log[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageNavigator, setPageNavigator] = useState<number>(1);
	const [filterType, setFilterType] = useState<string>("");
	const [filterValue, setFilterValue] = useState<string>("");
	const [cookie] = useCookies(["access"]);
	const apiAccess = {
		headers: { Authorization: `Bearer ${cookie["access"]}` },
	};
	const ITEMS_PER_PAGE: number = 50;
	const [loading, setLoading] = useState<boolean>(false);

	const [users, setUsers] = useState<_User[]>([]);
	const showMessage = useRef<Ui5DialogDomRef>(null);
	const [messageTitle, setMessageTitle] = useState<string>("");
	const [messageContent, setMessageContent] = useState<string>("");

	/**
	 * Displays a dialog message
	 * @param title Message title
	 * @param content Message content
	 */
	const displayMessage = (title: string, content: string) => {
		setMessageTitle(title);
		setMessageContent(content);
		showMessage.current?.show();
	};

	/**
	 * Gets bill report master data
	 */
	useEffect(() => {
		const apiAccess = {
			headers: { Authorization: `Bearer ${cookie["access"]}` },
		};
		setLoading(true);
		axios
			.post(`${API_URL}/query/log-report`, {}, apiAccess)
			.then((response: any) => {
				setLogs(response.data.rows);
				setLoading(false);
			});
		axios.get(`${API_URL}/crud/_user`, apiAccess).then((response: any) => {
			setUsers(response.data);
		});
	}, [cookie]);

	/**
	 * Converts date to locale
	 * @param date Unclean date
	 * @returns Clean date
	 */
	const cleanDate = (date: any): string => {
		if (date === undefined || date === "" || date === null) {
			return "";
		}
		let result = new Date(date.toString());
		return result.toLocaleString("es-GT", { timeZone: "America/Guatemala" });
	};

	/**
	 * Pagination
	 */
	const indexOfLastItem: number = currentPage * ITEMS_PER_PAGE;
	const indexOfFirtsItem: number = indexOfLastItem - ITEMS_PER_PAGE;
	const currentLogs: any[] = logs.slice(indexOfFirtsItem, indexOfLastItem);
	const pageNumber: number = Math.ceil(logs.length / ITEMS_PER_PAGE);
	const pageAvailable: number = pageNumber - pageNavigator;
	const pageNumbers = [];
	for (
		let i = pageNavigator;
		i <= pageNavigator + (pageAvailable < 5 ? pageAvailable : 5);
		i++
	) {
		pageNumbers.push(i);
	}

	/**
	 * Filter constants
	 */
	const FILTER_BY_USER: string = "Nombre de usuario";

	/**
	 * Handles log filter
	 */
	const handleFilter = async () => {
		switch (filterType) {
			case FILTER_BY_USER:
				try {
					setLoading(true);
					const response = await axios.post(
						`${API_URL}/query/log-report-user`,
						{ filterValue: filterValue },
						apiAccess
					);
					setLogs(response.data.rows);
				} catch (error) {
				} finally {
					setLoading(false);
				}
				break;
		}
	};

	/**
	 * Cleans logs data
	 * @returns Cleaned logs data
	 */
	const cleanReportData = (): Log[] => {
		let exportedLogs = [...logs].map((item) => ({ ...item }));
		return exportedLogs.map((log: any) => {
			log.time = cleanDate(log.time);
			return log;
		});
	};

	return (
		<div className={styles.logReport}>
			<Button
				icon="sys-back-2"
				onClick={() => history.push("/reports")}
				design="Emphasized"
			>
				Atras
			</Button>
			<br />
			<Form>
				<FormGroup titleText="Filtrar">
					<FormItem label={"Entrada"}>
						<ComboBox
							style={{ width: "100%" }}
							onChange={(e) => setFilterValue(e.target.value.toString())}
							value={filterValue}
							disabled={filterType === ""}
						>
							{users.map((user: _User, i) => (
								<ComboBoxItem key={i} text={user.nickname} />
							))}
						</ComboBox>
					</FormItem>
					<FormItem label={"Tipo de filtro"}>
						<ComboBox
							style={{ width: "100%" }}
							onChange={(e) => {
								setFilterType(e.target.value.toString());
								setFilterValue("");
							}}
							value={filterType}
						>
							<ComboBoxItem text={FILTER_BY_USER} />
						</ComboBox>
					</FormItem>
					<FormItem label={""}>
						<Button
							design="Positive"
							style={{ width: "100%" }}
							icon="search"
							onClick={handleFilter}
						>
							Buscar
						</Button>
					</FormItem>
					<FormItem label={""}>
						<CSVLink
							data={cleanReportData()}
							filename={`bill-report-${new Date().toLocaleString("es-GT", {
								timeZone: "America/Guatemala",
							})}`}
							style={{ width: "100%" }}
						>
							<Button icon="excel-attachment" style={{ width: "100%" }}>
								Descargar CSV
							</Button>
						</CSVLink>
					</FormItem>
				</FormGroup>
			</Form>
			<br />
			<FlexBox justifyContent="End">
				{pageNavigator > 1 && (
					<Button
						icon="close-command-field"
						onClick={() => setPageNavigator((prev: any) => prev - 1)}
					/>
				)}
				{pageNumbers.map((number: any) => (
					<Button
						key={number}
						onClick={() => setCurrentPage(number)}
						design={currentPage === number ? "Emphasized" : "Default"}
					>
						{number}
					</Button>
				))}
				{pageAvailable > 5 && (
					<Button
						icon="open-command-field"
						onClick={() => setPageNavigator((prev: any) => prev + 1)}
					/>
				)}
			</FlexBox>
			<br />
			{loading && <Loader />}
			<Table
				columns={
					<>
						<TableColumn demandPopin minWidth={600} popinText="Fecha">
							<Label>Fecha</Label>
						</TableColumn>
						<TableColumn>
							<Label>Usuario</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={600} popinText="Contenido">
							<Label>Contenido</Label>
						</TableColumn>
						<TableColumn demandPopin minWidth={600} popinText="Recurso">
							<Label>Recurso</Label>
						</TableColumn>
					</>
				}
			>
				{currentLogs.map((log: Log, i) => (
					<TableRow key={i}>
						<TableCell>
							<Label>{cleanDate(log.time)}</Label>
						</TableCell>
						<TableCell>
							<Label>{log._user}</Label>
						</TableCell>
						<TableCell>
							<Button
								onClick={() =>
									displayMessage(
										cleanDate(log.time) + "  " + log._user + "  " + log.uri,
										log.body
									)
								}
								icon="tree"
							>
								Mostrar
							</Button>
						</TableCell>
						<TableCell>
							<Label>{log.uri}</Label>
						</TableCell>
					</TableRow>
				))}
			</Table>
			<Dialog
				ref={showMessage}
				footer={
					<div style={{ padding: "5px" }}>
						<Button
							onClick={() => showMessage.current?.close()}
							design="Emphasized"
						>
							Cerrar
						</Button>
					</div>
				}
				headerText={messageTitle}
			>
				<div style={{ padding: "5px" }}>{messageContent}</div>
			</Dialog>
		</div>
	);
}
