import { TableRow, TableCell } from "@mui/material";

interface SpanTableProps {
  data: any[];
}

export const SpanTable: React.FC<SpanTableProps> = ({ data }) => {
  return (
    <>
      {data.map((e) => (
        <TableRow>
          <TableCell colSpan={e.span} />
          <TableCell align="center" sx={e.sx} key={e.key}>
            {e.key}
          </TableCell>
          <TableCell align="center" sx={e.sx} key={e.value}>
            {e.value}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
