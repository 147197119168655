import { Box, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MaterialReactTable from "material-react-table";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import LoadingTable from "../loading-table/LoadingTable";
import ShowError from "../show-error/ShowError";
import useFetch from "../../hooks/useFetch";
import api from "../../app/interceptor";
import { useEffect, useMemo, useState } from "react";
import getFields from "./Fields";
import { useHistory } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import Pagination from "../pagination/Pagination";
import FilterBillTable from "../bill-report/FilterBillTable";

const QuotationNewReport = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);

  const [filter, setFilter] = useState({});
  const dataRaw = useFetch("/quotation/report", {
    pageIndex: pageIndex,
    pageSize: pageSize,
    filter: filter,
  });

  const [quotationId, setQuotationId] = useState(undefined);
  const [showFilter, setShowFilter] = useState(false);

  const columns: any[] = useMemo(() => getFields(setQuotationId), []);
  const quotations: any = useMemo(() => dataRaw.data || [], [dataRaw.data]);

  useEffect(() => {
    if (quotationId) {
      viewFile(`/external/quotation/${quotationId}`);
    }
  }, [quotationId]);

  useEffect(() => {
    dataRaw.refetch({});
    setPageIndex(0);
  }, [filter]);

  useEffect(() => {
    dataRaw.refetch({});
  }, [pageSize, pageIndex]);

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    try {
      const response = await api.get(url, { responseType: "blob" });
      console.log(response.data);
      let _url: any = window.URL.createObjectURL(response.data);
      window.open(_url, "_blank")?.focus();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Cleans quotations data
   * @returns Cleaned quotations data
   */
  const cleanReportData = (): any[] => {
    let exportedQuotations = [...quotations].map((item: any) => ({ ...item }));
    return exportedQuotations.map((quotation: any) => {
      quotation.creationdate = cleanDate(quotation.creationdate);
      quotation.billingdate = cleanDate(quotation.billingdate);
      return quotation;
    });
  };

  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result.toLocaleString("es-GT").split(" ")[0];
  };

  if (dataRaw.status === "loading") {
    return <LoadingTable />;
  }

  if (dataRaw.status === "error") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Button
          sx={{ marginRight: "auto", marginBottom: 2 }}
          onClick={() => history.push("/reports")}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <ShowError
          title="Error Al Cargar el Reporte"
          type="error"
          message="Ha ocurrido un error inesperado al cargar la informacion del reporte."
        />
      </Box>
    );
  }

  const onChangeFilter = (values: any) => {
    console.log("values: ", values);
    if (Object.keys(values).length === 0) {
      setFilter({});
      return;
    }
    Object.keys(values).forEach((key) => {
      if (values[key] === "" || values[key] === undefined) {
        delete values[key];
      }
    });

    setFilter({ ...filter, ...values });
  };

  if (dataRaw.status === "error") {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
        <Button
          sx={{ marginRight: "auto", marginBottom: 2 }}
          onClick={() => history.push("/reports")}
        >
          <ArrowBackIosNewIcon />
        </Button>
        <ShowError
          title="Error Al Cargar el Reporte"
          type="error"
          message="Ha ocurrido un error inesperado al cargar la informacion del reporte."
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Button
          sx={{ marginRight: "auto", marginBottom: 2 }}
          onClick={() => history.push("/reports")}
          variant="contained"
        >
          <ArrowBackIosNewIcon />
          Atras
        </Button>
        <CSVLink
          data={cleanReportData()}
          filename={`quotation-report-${new Date().toLocaleString("es-GT", {
            timeZone: "America/Guatemala",
          })}`}
          style={{ marginLeft: "auto", marginBottom: 2 }}
        >
          <Button variant="contained">
            <DownloadIcon />
            Descargar CSV
          </Button>
        </CSVLink>
      </Box>

      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: showFilter ? "80%" : "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "fit",
            }}
          >
            <Button
              sx={{ fontSize: "1.5rem", marginLeft: "auto" }}
              onClick={() => setShowFilter(!showFilter)}
              variant="contained"
              color="secondary"
            >
              Filtrar
              {!showFilter ? (
                <FilterListIcon sx={{ fontSize: "3rem" }} />
              ) : (
                <FilterListOffIcon sx={{ fontSize: "3rem" }} />
              )}
            </Button>
          </Box>

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageIndex={setPageIndex}
          />
          <MaterialReactTable
            columns={columns}
            data={quotations}
            positionPagination="top"
            enablePagination={false}
            enableFilters={false}
            enableSorting={true}
            enableColumnVirtualization={true}
            enableRowVirtualization
            muiTableContainerProps={{ sx: { maxHeight: "" } }}
            enableTopToolbar={true}
            enableColumnActions={true}
            rowNumberMode="original"
            muiTableBodyRowProps={{ hover: true }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          {showFilter && (
            <FilterBillTable
              onChangeFilter={onChangeFilter}
              fields={[
                {
                  id: "quotationid",
                  name: "quotationid",
                  label: "Número de Proforma",
                  placeholder: "Busca por #Proforma",
                  type: "number",
                },
                {
                  id: "productionorder",
                  name: "productionorder",
                  label: "Orden de Produccion",
                  placeholder: "#Ord. Produccion",
                  type: "string",
                },
                {
                  id: "purchaseorder",
                  name: "purchaseorder",
                  label: "Orden de Compra",
                  placeholder: "#Ord. Compra",
                  type: "string",
                },
                {
                  id: "customer",
                  name: "customer",
                  label: "Nombre del Cliente",
                  placeholder: "Nombre de Cliente",
                  type: "string",
                },
                {
                  id: "grandtotal",
                  name: "grandtotal",
                  label: "Monto Total",
                  placeholder: "Total Proforma",
                  type: "number",
                },
              ]}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuotationNewReport;
