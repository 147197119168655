import styles from "./Shipping.module.css";

import {
	ResponsiveGridLayout,
	Input,
	Icon,
	Panel,
	Button,
	DatePicker,
	ComboBox,
	ComboBoxItem,
	Table,
	TableColumn,
	TableRow,
	TableCell,
	Label,
	TextArea,
	Dialog,
	MessageBox,
	MessageBoxActions,
} from "@ui5/webcomponents-react";
import { useState, useEffect, useRef } from "react";
import { ShippingDTO, shippingInitialState } from "../../models/ShippingDTO";
import axios from "axios";
import { API_URL } from "../../app/constants";
import { Ui5DialogDomRef } from "@ui5/webcomponents-react/interfaces/Ui5DialogDomRef";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

export function Shipping(props: any) {
	/**
	 * Shipping global state and searching
	 */
	const [shipping, setShipping] = useState<ShippingDTO>(shippingInitialState);
	const [shippingSearch, setShippingSearch] = useState<string>("");
	const [quotationSearch, setQuotationSearch] = useState<string>("");

	/**
	 * Shipping properties
	 */
	const { shippingId } = useParams<any>();
	const [quotation, setQuotation] = useState<any>({});
	const [customer, setCustomer] = useState<any>({});
	const [products, setProducts] = useState<any>([]);
	const [currentProduct, setCurrentProduct] = useState<any>({});
	const [productTypes, setProductTypes] = useState<any[]>([]);
	const [billingCodes, setBillingCodes] = useState<any[]>([]);
	const [cartProducts, setCartProducts] = useState<any[]>([]);
	const showMessage = useRef<Ui5DialogDomRef>(null);
	const [messageTitle, setMessageTitle] = useState<string>("");
	const [messageContent, setMessageContent] = useState<string>("");
	const [deleteShippingConfirmation, setDeleteConfirmation] = useState(false);
	const [cookie] = useCookies(["access"]);
	const [userCookie] = useCookies(["user"]);
	const [nicknameCookie] = useCookies(["nickname"]);
	const apiAccess = {
		headers: { Authorization: `Bearer ${cookie["access"]}` },
	};

	/**
	 * Gets shipping data by id
	 */
	useEffect(() => {
		if (shippingId) {
			setShippingSearch(shippingId);
			findShippingById(shippingId);
		}
		// eslint-disable-next-line
	}, [shippingId]);

	/**
	 * Loads initial data to global props
	 */
	useEffect(() => {
		if (props.data) {
			setProductTypes(props.data.productTypes);
			setBillingCodes(props.data.billingCodes);
		} else {
			const apiAccess = {
				headers: { Authorization: `Bearer ${cookie["access"]}` },
			};
			axios
				.get(`${API_URL}/crud/productType`, apiAccess)
				.then((response: any) => {
					const serverProducTypes = response.data.map((productType: any) => (
						<ComboBoxItem
							key={productType.producttypeid}
							text={productType.name}
						/>
					));
					setProductTypes(serverProducTypes);
				});
			axios
				.get(`${API_URL}/crud/billingCode`, apiAccess)
				.then((response: any) => {
					const serverBillingCodes = response.data.map((billingCode: any) => (
						<ComboBoxItem
							key={billingCode.billingcodeid}
							text={billingCode.name}
						/>
					));
					setBillingCodes(serverBillingCodes);
				});
		}
	}, [props, cookie]);

	/**
	 * Gets quotation data
	 * @returns Quotations data or an error
	 */
	const getQuotatation = async (quotationId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/find-quotation-for-shipping-data`,
				{ quotationid: quotationId },
				apiAccess
			);
			return [response.data.rows[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Searches customer data
	 */
	const findCustomerData = async (customerId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/find-customer-by-id`,
				{ customerId: customerId },
				apiAccess
			);
			return [response.data.rows[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Gets quotations products data
	 * @param quotationId Quotation id
	 * @returns Quotations products data or and error
	 */
	const getQuotationProducts = async (quotationId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/get-available-products-for-delivery`,
				{ quotationId: quotationId },
				apiAccess
			);
			return [response.data.rows, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Searches quotation data
	 */
	const findQuotation = async () => {
		if (quotationSearch === "") {
			return;
		}
		const [quotationData] = await getQuotatation(quotationSearch);
		if (!quotationData) {
			restoreShippingState();
			displayMessage("Error al buscar proforma", "La proforma no existe.");
			return;
		}
		setShipping({ ...shipping, quotationId: quotationData.quotationid });
		setQuotation(quotationData);
		const [customerData] = await findCustomerData(quotationData.customerid);
		if (!customerData) {
			restoreShippingState();
			displayMessage(
				"Error al buscar cliente de proforma",
				"El cliente no existe."
			);
			return;
		}
		setCustomer(customerData);
		const [quotationProducts] = await getQuotationProducts(quotationSearch);
		if (quotationProducts) {
			setProducts(quotationProducts);
		}
	};

	/**
	 * Handles product combobox change
	 * @param e event
	 */
	const handleProductsComboBox = async (e: any) => {
		const name = e.target.value.toString();
		const code = name.split(" ")[0];
		for (let i = 0; i < products.length; i++) {
			const product = products[i];
			if (product.productid.toString() === code.toString()) {
				setCurrentProduct(product);
				return;
			}
		}
	};

	/**
	 * Gets current products type name
	 * @returns Products type name
	 */
	const getCurrentProductType = (): string => {
		if (currentProduct.producttypeid) {
			return productTypes
				.find(
					(productType: any) =>
						productType.key.toString() ===
						currentProduct?.producttypeid.toString()
				)
				.props.text.toString();
		}
		return "";
	};

	/**
	 * Gets current products billing code name
	 * @returns Products billing code name
	 */
	const getCurrentBillingCode = (): string => {
		if (currentProduct.billingcodeid) {
			return billingCodes
				.find(
					(billingCode: any) =>
						billingCode.key.toString() ===
						currentProduct?.billingcodeid.toString()
				)
				.props.text.toString();
		}
		return "";
	};

	/**
	 * Adds a product for shipping
	 */
	const addProduct = async () => {
		if (currentProduct.quotationproductid) {
			setCartProducts([...cartProducts, currentProduct]);
			setProducts(
				products.filter(
					(product: any) =>
						product.quotationproductid !== currentProduct.quotationproductid
				)
			);
			setCurrentProduct({});
		}
	};

	/**
	 * Updates shipping resume
	 */
	useEffect(() => {
		if (cartProducts.length > 0) {
			let grandTotal = 0;
			for (let i = 0; i < cartProducts.length; i++) {
				const cartProduct = cartProducts[i];
				grandTotal += parseFloat(cartProduct.subtotal);
			}
			grandTotal = parseFloat(grandTotal.toFixed(2));
			if (shipping.grandTotal !== grandTotal) {
				const totalWithVat = (grandTotal / 1.12).toFixed(2);
				const totalWithoutVat = (parseFloat(totalWithVat) * 0.12).toFixed(2);
				setShipping({
					...shipping,
					totalWithVat: parseFloat(totalWithVat),
					totalWithoutVat: parseFloat(totalWithoutVat),
					grandTotal: grandTotal,
				});
			}
		}
	}, [cartProducts, shipping]);

	/**
	 * Save shipping request
	 * @param shipping Shipping data
	 * @returns Shippings save data or an error
	 */
	const saveShippingRequest = async (shipping: any) => {
		try {
			const response = await axios.post(
				`${API_URL}/crud/shipping`,
				shipping,
				apiAccess
			);
			return [response.data.data[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Save product request
	 * @param product Product data
	 * @returns Products save data or an error
	 */
	const saveShippingProductRequest = async (product: any) => {
		try {
			const response = await axios.post(
				`${API_URL}/crud/shippingProduct`,
				product,
				apiAccess
			);
			return [response.data, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Handles shipping save
	 */
	const handleShippingSave = async () => {
		let currentShipping: any = { ...shipping };
		let currentDate = new Date();
		currentDate.setHours(currentDate.getHours() - 6);
		currentShipping.creationDate = currentDate.toISOString();
		currentShipping.nickname = nicknameCookie["nickname"];
		delete currentShipping.shippingId;
		const [shippingSaveData] = await saveShippingRequest(currentShipping);
		if (!shippingSaveData) {
			displayMessage(
				"Error al guardar envío",
				"Verifica los datos e intenta nuevamente."
			);
			return;
		}
		setShipping({
			...shipping,
			shippingId: shippingSaveData.shippingid,
			creationDate: shippingSaveData.creationdate,
			nickname: nicknameCookie["nickname"],
		});
		for (let i = 0; i < cartProducts.length; i++) {
			const cartProduct = cartProducts[i];
			await saveShippingProductRequest({
				shippingid: shippingSaveData.shippingid,
				quotationproductid: cartProduct.quotationproductid,
			});
		}
		displayMessage(
			"Envio guardado",
			`Envío #${shippingSaveData.shippingid} creado.`
		);
	};

	/**
	 * Update shipping request
	 * @param shipping Shipping data
	 * @returns Shippings update data or an error
	 */
	const updateShippingRequest = async (shipping: any) => {
		try {
			const response = await axios.put(
				`${API_URL}/crud/shipping`,
				shipping,
				apiAccess
			);
			return [response.data.data[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Delete shipping products request
	 * @param shippingId Shipping id
	 * @returns Shippings delete data or an error
	 */
	const deleteProductShippingRequest = async (shippingId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/delete-shipping-products`,
				{
					shippingId: shippingId,
				},
				apiAccess
			);
			return [response.data, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Handles shipping update
	 */
	const handleShippingUpdate = async () => {
		let currentShipping: any = { ...shipping };
		delete currentShipping.nickname;
		const shippingUpdateBody: any = {
			new: currentShipping,
			filter: {
				shippingid: currentShipping.shippingId,
			},
		};
		const [shippingUpdateData] = await updateShippingRequest(
			shippingUpdateBody
		);
		if (!shippingUpdateData) {
			displayMessage(
				"Error al actualizar envío",
				"Verifica los datos e intenta nuevamente."
			);
			return;
		}
		const [shippingDeleteProductsData] = await deleteProductShippingRequest(
			currentShipping.shippingId
		);
		if (!shippingDeleteProductsData) {
			displayMessage(
				"Error al actualizar envío",
				"Verifica los datos e intenta nuevamente."
			);
			return;
		}
		for (let i = 0; i < cartProducts.length; i++) {
			const cartProduct = cartProducts[i];
			await saveShippingProductRequest({
				shippingid: currentShipping.shippingId,
				quotationproductid: cartProduct.quotationproductid,
			});
		}
		displayMessage(
			"Envio actualizado",
			`Envío #${currentShipping.shippingId} actualizado.`
		);
	};

	/**
	 * Saves the current shipping
	 */
	const saveShipping = async () => {
		if (!(await verifyShippingIntegrity())) return;
		if (shipping.shippingId === 0) {
			handleShippingSave();
		} else {
			handleShippingUpdate();
		}
	};

	/**
	 * Gets shipping data
	 * @param shippingId Shipping id
	 * @returns Shippings data or an error
	 */
	const findShippingByIdRequest = async (shippingId: string) => {
		try {
			const result = await axios.post(
				`${API_URL}/query/find-shipping-by-id`,
				{
					shippingId: shippingId,
				},
				apiAccess
			);
			return [result.data.rows[0], null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Restores shipping initial stateD
	 */
	const restoreShippingState = () => {
		setShippingSearch("");
		setQuotationSearch("");
		setQuotation({});
		setCustomer({});
		setProducts([]);
		setCurrentProduct({});
		setCartProducts([]);
		setShipping({ ...shippingInitialState });
	};

	/**
	 * Maps shipping data
	 * @param shippingData Shipping unmaped data
	 */
	const cleanShippingData = (shippingData: any) => {
		setShipping({
			shippingId: shippingData.shippingid,
			quotationId: shippingData.quoationid,
			creationDate: shippingData.creationdate,
			deliveryDate: shippingData.deliverydate,
			shippingRemarks: shippingData.shippingremarks,
			totalWithVat: parseFloat(shippingData.totalwithvat),
			totalWithoutVat: parseFloat(shippingData.totalwithoutvat),
			grandTotal: parseFloat(shippingData.grandtotal),
			nickname: shippingData.nickname,
		});
	};

	/**
	 * Gets shippings products
	 * @param shippingId Shipping id
	 * @returns Shippings products
	 */
	const getShippingProducts = async (shippingId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/find-shipping-products-by-id`,
				{ shippingId: shippingId },
				apiAccess
			);
			return [response.data.rows, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Finds shipping data
	 */
	const findShippingById = async (shippingId: string) => {
		const [shippingData] = await findShippingByIdRequest(shippingId);
		if (!shippingData) {
			restoreShippingState();
			displayMessage("Error al buscar envío", "El envio no existe.");
			return;
		}
		cleanShippingData(shippingData);
		setQuotationSearch(shippingData.quotationid);
		const [quotationData] = await getQuotatation(shippingData.quotationid);
		if (!quotationData) {
			restoreShippingState();
			displayMessage(
				"Error al buscar proforma de envio",
				"La proforma no existe."
			);
			return;
		}
		setQuotation(quotationData);
		const [customerData] = await findCustomerData(quotationData.customerid);
		if (!customerData) {
			restoreShippingState();
			displayMessage(
				"Error al buscar cliente de envio",
				"El cliente no existe."
			);
			return;
		}
		setCustomer(customerData);
		const [shippingProducts] = await getShippingProducts(
			shippingData.shippingid
		);
		if (shippingProducts) {
			setCartProducts(shippingProducts);
		}
		const [quotationProducts] = await getQuotationProducts(
			shippingData.quotationid
		);
		if (quotationProducts) {
			setProducts(quotationProducts);
		}
	};

	/**
	 * Deletes a product from the cart
	 */
	const deleteCartProducts = (product: any) => {
		setCartProducts(
			cartProducts.filter((currentProduct) => currentProduct !== product)
		);
		setProducts([...products, product]);
	};

	/**
	 * Import all products from the quotations
	 */
	const importAllProducts = () => {
		const allProducts = [...products];
		setCartProducts([...cartProducts, ...allProducts]);
		setProducts([]);
		setCurrentProduct({});
	};

	/**
	 * Displays a dialog message
	 * @param title Message title
	 * @param content Message content
	 */
	const displayMessage = (title: string, content: string) => {
		setMessageTitle(title);
		setMessageContent(content);
		showMessage.current?.show();
	};

	/**
	 * Deletes the current shipping
	 * @param shippingId Shipping id
	 * @returns Shippings delete info or an error
	 */
	const deleteShippingRequest = async (shippingId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/delete-shipping`,
				{
					shippingId: shippingId,
				},
				apiAccess
			);
			return [response.data, null];
		} catch (error) {
			return [null, error];
		}
	};

	/**
	 * Deletes the current shipping products
	 * @param shippingId Shipping id
	 * @returns Shippings products delete info or an error
	 */
	const deleteShippingProductRequest = async (shippingId: string) => {
		try {
			const response = await axios.post(
				`${API_URL}/query/delete-shipping-products`,
				{ shippingId: shippingId },
				apiAccess
			);
			return [response.data, null];
		} catch (error) {
			return [error, null];
		}
	};

	/**
	 * Handles combobox billing confirmation
	 * @param event Message box confirmation
	 */
	const handleDeleteShippingConfirmation = async (event: any) => {
		if (event.detail.action === MessageBoxActions.OK) {
			setDeleteConfirmation(false);
			try {
				const currentShipping = { ...shipping };
				await deleteShippingProductRequest(
					currentShipping.shippingId.toString()
				);
				await deleteShippingRequest(currentShipping.shippingId.toString());
			} catch (error) {
				displayMessage(
					"Error al eliminar envio",
					"Comunicate con el soporte IT."
				);
			}
			restoreShippingState();
		}
		setDeleteConfirmation(false);
	};

	/**
	 * Opens an file
	 * @param url File url
	 */
	const viewFile = async (url: any) => {
		fetch(url, apiAccess)
			.then((response) => response.blob())
			.then((blob) => {
				let _url: any = window.URL.createObjectURL(blob);
				window.open(_url, "_blank")?.focus();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	/**
	 * Verifies shipping data integrity
	 * @returns True if the shippings data is valid
	 */
	const verifyShippingIntegrity = async () => {
		if (shipping.deliveryDate === "") {
			displayMessage("Error", "Selecciona la fecha de entrega");
			return false;
		}
		if (shipping.shippingRemarks === "") {
			displayMessage("Error", "Ingresa las observaciones del envío");
			return false;
		}
		if (cartProducts.length === 0) {
			displayMessage("Error al crear envío", "Agrega productos al envío.");
			return;
		}
		return true;
	};

	return (
		<div className={styles.shipping}>
			<Panel headerText="Datos Generales">
				<ResponsiveGridLayout
					columnsXL={4}
					columnsL={2}
					columnsM={1}
					columnsS={1}
				>
					<div>
						<Input
							icon={<Icon name="shipping-status" />}
							onInput={(e) => setShippingSearch(e.target.value.toString())}
							placeholder="Correlativo Envio"
							value={shippingSearch}
							className={styles.input}
						/>
					</div>
					<div>
						<Button
							icon="search"
							onClick={() => findShippingById(shippingSearch)}
							iconEnd
							className={styles.button}
						>
							Buscar Envio
						</Button>
					</div>
					<div>
						<Input
							icon={<Icon name="sales-order" />}
							onInput={(e) => setQuotationSearch(e.target.value.toString())}
							placeholder="Correlativo Proforma"
							className={styles.input}
							value={quotationSearch}
							readonly={shipping.shippingId !== 0}
						/>
					</div>
					<div>
						<Button
							icon="search"
							onClick={findQuotation}
							iconEnd
							className={styles.button}
							disabled={shipping?.shippingId !== 0}
						>
							Buscar Proforma
						</Button>
					</div>
					<div>
						<Input
							icon={<Icon name="number-sign" />}
							placeholder="Orden de Producción"
							className={styles.input}
							value={quotation.productionorder ? quotation.productionorder : ""}
							readonly
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="sales-order" />}
							placeholder="Orden de Compra"
							className={styles.input}
							value={quotation.purchaseorder ? quotation.purchaseorder : ""}
							readonly
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="employee-pane" />}
							placeholder="Asesor"
							className={styles.input}
							value={quotation.seller ? quotation.seller : ""}
							readonly
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="globe" />}
							placeholder="Tipo de Envío"
							className={styles.input}
							value={quotation.quotationtype ? quotation.quotationtype : ""}
							readonly
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="calendar" />}
							placeholder="Fecha de Creación"
							className={styles.input}
							value={
								shipping.creationDate === ""
									? new Date().toLocaleString("es-GT", {
											timeZone: "America/Guatemala",
									  })
									: shipping.creationDate?.split("T")[0]
							}
							readonly
						/>
					</div>
					<div>
						<DatePicker
							className={styles.input}
							onChange={(e) =>
								setShipping({
									...shipping,
									deliveryDate: e.target.value.toString(),
								})
							}
							primaryCalendarType="Gregorian"
							placeholder="Fecha de Entrega"
							style={{
								border: shipping.deliveryDate === "" ? "2px solid red" : "",
							}}
							formatPattern={"yyyy-MM-dd"}
							value={
								shipping.deliveryDate !== ""
									? shipping.deliveryDate.split("T")[0]
									: ""
							}
							id="delivery-datapicker"
						/>
					</div>
					<div>
						<TextArea
							className=""
							onInput={(e) =>
								setShipping({
									...shipping,
									shippingRemarks: e.target.value.toString(),
								})
							}
							placeholder="Observaciones de Envio"
							style={{
								border: shipping.shippingRemarks === "" ? "2px solid red" : "",
							}}
						/>
					</div>
				</ResponsiveGridLayout>
			</Panel>
			<Panel headerText="Cliente">
				<ResponsiveGridLayout
					columnsXL={2}
					columnsL={2}
					columnsM={1}
					columnsS={1}
				>
					<div>
						<Input
							icon={<Icon name="number-sign" />}
							placeholder="NIT"
							className={styles.input}
							readonly
							value={customer.nit ? customer.nit : ""}
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="customer" />}
							placeholder="Nombre Cliente"
							className={styles.input}
							readonly
							value={customer.fullname ? customer.fullname : ""}
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="call" />}
							placeholder="Teléfono"
							className={styles.input}
							readonly
							value={customer.phone ? customer.phone : ""}
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="email" />}
							placeholder="Correo"
							className={styles.input}
							readonly
							value={customer.email ? customer.email : ""}
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="addresses" />}
							placeholder="Dirección"
							className={styles.input}
							readonly
							value={customer.address ? customer.address : ""}
						/>
					</div>
					<div>
						<Input
							icon={<Icon name="shipping-status" />}
							placeholder="Dirección de entrega"
							className={styles.input}
							readonly
							value={customer.deliveryaddress ? customer.deliveryaddress : ""}
						/>
					</div>
				</ResponsiveGridLayout>
			</Panel>
			{products.length > 0 && (
				<Panel headerText="Agregar Productos">
					<ResponsiveGridLayout
						columnsXL={2}
						columnsL={2}
						columnsM={1}
						columnsS={1}
					>
						<div>
							<ComboBox
								icon={<Icon name="product" />}
								onChange={(e) => handleProductsComboBox(e)}
								placeholder="Productos en Proforma"
								className={styles.input}
								value={
									currentProduct.quotationproductid
										? `${currentProduct.productid} ${currentProduct.quickoverview}`
										: ""
								}
							>
								{products.map((product: any, i: number) => (
									<ComboBoxItem
										key={i}
										text={`${product.productid} ${product.quickoverview}`}
									/>
								))}
							</ComboBox>
						</div>
						<div>
							<Button
								icon="add"
								onClick={addProduct}
								iconEnd
								className={styles.button}
							>
								Agregar
							</Button>
						</div>
						<div>
							<Input
								icon={<Icon name="hint" />}
								placeholder="Descripción Rápida"
								className={styles.input}
								value={
									currentProduct.quickoverview
										? currentProduct.quickoverview
										: ""
								}
								readonly
							/>
						</div>

						<div>
							<Input
								icon={<Icon name="product" />}
								placeholder="Tipo de Producto"
								className={styles.input}
								value={getCurrentProductType()}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="product" />}
								placeholder="Código de Facturación"
								className={styles.input}
								value={getCurrentBillingCode()}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="legend" />}
								placeholder="Descripción Detallada"
								className={styles.input}
								value={
									currentProduct.detaileddescription
										? currentProduct.detaileddescription
										: ""
								}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="number-sign" />}
								placeholder="Cantidad"
								className={styles.input}
								value={currentProduct.quantity ? currentProduct.quantity : ""}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="lead" />}
								placeholder="Precio unitario"
								className={styles.input}
								value={
									currentProduct.unitprice ? currentProduct?.unitprice : ""
								}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="waiver" />}
								placeholder="Descuento"
								className={styles.input}
								value={currentProduct.discount ? currentProduct.discount : ""}
								readonly
							/>
						</div>
						<div>
							<Input
								icon={<Icon name="lead-outdated" />}
								placeholder="Subtotal"
								className={styles.input}
								value={currentProduct.subtotal ? currentProduct.subtotal : ""}
								readonly
							/>
						</div>
					</ResponsiveGridLayout>
					<div style={{ width: "100%", textAlign: "center" }}>
						<Button
							icon="add-product"
							onClick={importAllProducts}
							iconEnd
							className={styles.button}
							design="Positive"
						>
							Importar todos los productos
						</Button>
					</div>
				</Panel>
			)}
			<Panel headerText="Listado de Productos">
				<Table
					style={{
						border: cartProducts.length === 0 ? "2px solid red" : "",
					}}
					columns={
						<>
							<TableColumn style={{ width: "12rem" }}>
								<Label>Código de Producto</Label>
							</TableColumn>
							<TableColumn
								demandPopin
								minWidth={800}
								popinText="Código de Facturación"
							>
								<Label>Código de Facturación</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={800} popinText="Tipo">
								<Label>Tipo</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={800} popinText="Cantidad">
								<Label>Cantidad</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={800} popinText="Descripcion">
								<Label>Descripcion</Label>
							</TableColumn>
							<TableColumn
								demandPopin
								minWidth={800}
								popinText="Precio Unitario"
							>
								<Label>Precio Unitario</Label>
							</TableColumn>
							<TableColumn demandPopin minWidth={800} popinText="Descuento">
								<Label>Descuento</Label>
							</TableColumn>
							<TableColumn>
								<Label>Subtotal</Label>
							</TableColumn>
							<TableColumn>
								<Label>Acciones</Label>
							</TableColumn>
						</>
					}
				>
					{cartProducts.map((product, i) => (
						<TableRow key={i}>
							<TableCell>
								<Label>{product.productid}</Label>
							</TableCell>
							<TableCell>
								<Label>
									{
										billingCodes.filter(
											(billingcode: any) =>
												billingcode.key === product.billingcodeid.toString()
										)[0].props.text
									}
								</Label>
							</TableCell>
							<TableCell>
								<Label>
									{
										productTypes.filter(
											(productType: any) =>
												productType.key === product.producttypeid.toString()
										)[0].props.text
									}
								</Label>
							</TableCell>
							<TableCell>
								<Label>{product.quantity}</Label>
							</TableCell>
							<TableCell>
								<Label>{product.quickoverview}</Label>
							</TableCell>
							<TableCell>
								<Label>{product.unitprice}</Label>
							</TableCell>
							<TableCell>
								<Label>{`${product.discount}%`}</Label>
							</TableCell>
							<TableCell>
								<Label>{product.subtotal}</Label>
							</TableCell>
							<TableCell>
								<Button
									icon="delete"
									design="Negative"
									onClick={() => deleteCartProducts(product)}
								/>
							</TableCell>
						</TableRow>
					))}
				</Table>
			</Panel>
			<Panel headerText="Resumen">
				<ResponsiveGridLayout
					columnsXL={2}
					columnsL={2}
					columnsM={2}
					columnsS={1}
				>
					<div>
						<strong>Autor</strong>
					</div>
					<div>
						<Input
							readonly
							className={styles.input}
							value={shipping.nickname ? shipping.nickname : ""}
						/>
					</div>
					<div>
						<strong>Subtotal</strong>
					</div>
					<div>
						<Input
							readonly
							className={styles.input}
							value={`${shipping.totalWithoutVat.toFixed(2)} ${
								quotation?.currencyid === 2 ? "USD" : "QTZ"
							}`}
							id="shipping-wvat"
						/>
					</div>
					<div>
						<strong>IVA</strong>
					</div>
					<div>
						<Input
							readonly
							className={styles.input}
							value={`${shipping.totalWithVat.toFixed(2)} ${
								quotation?.currencyid === 2 ? "USD" : "QTZ"
							}`}
							id="shipping-vat"
						/>
					</div>
					<div>
						<strong>Total</strong>
					</div>
					<div>
						<Input
							readonly
							className={styles.input}
							value={`${shipping.grandTotal.toFixed(2)} ${
								quotation?.currencyid === 2 ? "USD" : "QTZ"
							}`}
							id="shipping-total"
						/>
					</div>
					<div>
						<Button
							icon="create"
							onClick={saveShipping}
							iconEnd
							className={styles.button}
							design="Emphasized"
							disabled={userCookie.user.toString() !== "1"}
						>
							{shipping.shippingId === 0 ? "Crear Envio" : "Modificar Envio"}
						</Button>
					</div>
					{shipping.shippingId !== 0 && (
						<div>
							<Button
								icon="detail-view"
								onClick={() =>
									viewFile(
										`${API_URL}/external/shipping/${shipping.shippingId}`
									)
								}
								iconEnd
								className={styles.button}
								design="Positive"
							>
								Visualizar Envio
							</Button>
						</div>
					)}
					{shipping.shippingId !== 0 && (
						<div>
							<Button
								icon="print"
								onClick={() =>
									viewFile(
										`${API_URL}/external/shippingPrint/${shipping.shippingId}`
									)
								}
								iconEnd
								className={styles.button}
								design="Positive"
							>
								Imprimir Envio
							</Button>
						</div>
					)}
					{shipping.shippingId !== 0 && (
						<div>
							<Button
								icon="add"
								onClick={() => {
									restoreShippingState();
								}}
								iconEnd
								className={styles.button}
								disabled={userCookie.user.toString() !== "1"}
							>
								Nuevo Envio
							</Button>
						</div>
					)}
					{shipping.shippingId !== 0 && (
						<div>
							<Button
								icon="delete"
								onClick={() => setDeleteConfirmation(true)}
								iconEnd
								className={styles.button}
								design="Negative"
								disabled={userCookie.user.toString() !== "1"}
							>
								Eliminar Envio
							</Button>
						</div>
					)}
				</ResponsiveGridLayout>
			</Panel>
			<Dialog
				ref={showMessage}
				footer={
					<div style={{ padding: "5px" }}>
						<Button
							onClick={() => showMessage.current?.close()}
							design="Emphasized"
						>
							Cerrar
						</Button>
					</div>
				}
				headerText={messageTitle}
			>
				<div style={{ padding: "5px" }}>{messageContent}</div>
			</Dialog>
			<>
				<MessageBox
					open={deleteShippingConfirmation}
					onClose={handleDeleteShippingConfirmation}
					actions={[MessageBoxActions.OK, MessageBoxActions.Cancel]}
				>
					¿Desea eliminar el envio?
				</MessageBox>
			</>
		</div>
	);
}
