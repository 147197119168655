export interface _User {
	_userid: number;
	_usertypeid: number;
	sellerid: number;
	nickname: string;
	password: string;
}

export const _userInitialValue: _User = {
	_userid: 0,
	_usertypeid: 0,
	sellerid: 0,
	nickname: "",
	password: "",
};
