export interface QuotationDTO {
	quotationId: number;
	creationDate?: string;
	billingDate?: string;
	dateOfDelivery?: string;
	productionOrder: string;
	purchaseOrder: string;
	description: string;
	observations: string;
	dteID: string;
	quotationStateId: number;
	quotationTypeId: number;
	export: boolean;
	currencyId: number;
	sellerId: number;
	customerId: number;
	totalWithVat: number;
	totalDiscounts: number;
	totalWithoutVat: number;
	grandTotal: number;
	deliveryAddress: string;
	deliveryCountry: string;
	deliveryMethodId: number;
	incotermId: number;
	warrantyId: number;
	warrantyDescription: string;
	consigneeName: string;
	consigneeAddress: string;
	consigneeCode: string;
	exporterName?: string;
	exporterCode?: string;
	buyersName: string;
	buyersAddress: string;
	buyersCode: string;
	exportDescription: string;
	nickname: string;
}

export const quotationInitialState: QuotationDTO = {
	quotationId: 0,
	creationDate: "",
	billingDate: "",
	dateOfDelivery: "",
	productionOrder: "",
	purchaseOrder: "",
	description: "",
	observations: "",
	dteID: "",
	quotationStateId: 0,
	quotationTypeId: 0,
	export: false,
	currencyId: 0,
	sellerId: 0,
	customerId: 0,
	totalWithVat: 0,
	totalDiscounts: 0,
	totalWithoutVat: 0,
	grandTotal: 0,
	deliveryAddress: "",
	deliveryCountry: "",
	deliveryMethodId: 0,
	incotermId: 0,
	warrantyId: 0,
	warrantyDescription: "",
	consigneeName: "",
	consigneeAddress: "",
	consigneeCode: "",
	exporterName: "",
	exporterCode: "",
	buyersName: "",
	buyersAddress: "",
	buyersCode: "",
	exportDescription: "",
	nickname: "",
};
