import {Grid, TextField} from "@mui/material";
import {BillInfo} from "../../models/billInfo";

interface propsTableTollbar {
  bill: BillInfo;
}

export const TableToolbar: React.FC<propsTableTollbar> = ({bill}) => {
  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanBillingDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result
      .toLocaleString("es-GT", {timeZone: "America/Guatemala"})
      .split(" ")[0]
      .replaceAll(",", "");
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{backgroundColor: "#e5e7e9", padding: "1.5rem"}}
    >
      <Grid item xs={5} md={4}>
        <TextField
          label="#Autorizacion"
          variant="standard"
          sx={{width: "80%"}}
          value={bill.authorizationnumber}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Serie-Documento"
          variant="standard"
          sx={{width: "100%"}}
          value={`${bill.documentnumber}-${bill.serienumber}`}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Email"
          variant="standard"
          sx={{width: "100%"}}
          value={bill.customeremail}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Nit"
          variant="standard"
          sx={{width: "100%"}}
          value={bill.customernit}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Nombre Receptor"
          variant="standard"
          sx={{width: "100%"}}
          value={bill.customername}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Tipo Factura y Estado"
          variant="standard"
          sx={{width: "100%"}}
          value={`${bill.billtype}-${bill.billstatus}`}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Moneda"
          variant="standard"
          sx={{width: "100%"}}
          value={bill.currency}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Fecha Emision"
          variant="standard"
          sx={{width: "100%"}}
          value={cleanBillingDate(bill.emissiondate)}
        ></TextField>
      </Grid>
      <Grid item xs={5} md={4}>
        <TextField
          label="Fecha Certificacion"
          variant="standard"
          sx={{width: "100%"}}
          value={cleanBillingDate(bill.certificationdate)}
        ></TextField>
      </Grid>
    </Grid>
  );
};
