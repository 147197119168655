import { useEffect, useState } from "react";
import styles from "./Customer.module.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MenuItem } from "@mui/material";
import { Panel, ResponsiveGridLayout } from "@ui5/webcomponents-react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TypeOf, z } from "zod";

interface ICustomerForm {
  showCreateClient: (value: boolean) => void;
  setCustomerForm: (value: any) => void;
}

const userTypeId = [
  {
    key: 1,
    value: "NIT",
  },
  {
    key: 2,
    value: "CUI",
  },
  {
    key: 3,
    value: "Extranjero",
  },
];

const schema = z.object({
  idnumber: z
    .string({
      required_error: "Campo obligatorio",
      invalid_type_error: "Debe ser una cadena de texto",
    })
    .min(1, { message: "Cambo obligatorio" }),
  fullname: z
    .string({
      required_error: "Campo obligatorio",
      invalid_type_error: "Debe ser una cadena de texto",
    })
    .optional(),
  phone: z
    .string({
      required_error: "Campo obligatorio",
      invalid_type_error: "Debe ser una cadena de texto",
    })
    .min(1, { message: "Cambo obligatorio" }),

  email: z
    .string({
      required_error: "Campo obligatorio",
      invalid_type_error: "Debe ser una cadena de texto",
    })
    .email({ message: "Formato de email no valido" })
    .min(1, { message: "Cambo obligatorio" }),
  address: z
    .string({
      required_error: "Campo obligatorio",
      invalid_type_error: "Debe ser una cadena de texto",
    })
    .min(1, { message: "Cambo obligatorio" }),
});

type inputSchema = TypeOf<typeof schema>;
export const CustomerForm: React.FC<ICustomerForm> = ({
  showCreateClient,
  setCustomerForm,
}) => {
  const methods: any = useForm<inputSchema>({
    resolver: zodResolver(schema),
  });
  const [idType, setIdType] = useState<number>(1);

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { isSubmitSuccessful, errors },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onsubmitHandler: SubmitHandler<inputSchema> = (values) => {
    setCustomerForm({ ...values, idtype: idType });
  };

  return (
    <FormProvider {...methods}>
      <Panel className={styles.headerText} headerText="Crear Cliente">
        <form onSubmit={handleSubmit(onsubmitHandler)}>
          <ResponsiveGridLayout
            columnsXL={2}
            columnsL={2}
            columnsM={1}
            columnsS={1}
          >
            <div>
              <TextField
                className={styles.input}
                size="small"
                label="Tipo de Identificacion"
                select
                onChange={(e: any) => setIdType(Number(e.target.value))}
                value={idType}
              >
                {userTypeId.map((element) => (
                  <MenuItem key={element.key} value={element.key}>
                    {element.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <Controller
                control={control}
                defaultValue=""
                name={"idnumber"}
                render={({ field }) => (
                  <TextField
                    className={styles.input}
                    label="Documento Identificacion"
                    size="small"
                    error={!!errors["idnumber"]}
                    helperText={
                      errors["idnumber"] ? errors["idnumber"].message : ""
                    }
                    {...register("idnumber")}
                    {...field}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                defaultValue=""
                name={"address"}
                render={() => (
                  <TextField
                    size="small"
                    className={styles.input}
                    multiline
                    rows={1}
                    label="Direccion"
                    error={!!errors["address"]}
                    helperText={
                      errors["address"] ? errors["address"].message : ""
                    }
                    {...register("address")}
                  ></TextField>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                defaultValue=""
                name={"email"}
                render={({ field }) => (
                  <TextField
                    className={styles.input}
                    label="Correo"
                    size="small"
                    error={!!errors["email"]}
                    helperText={errors["email"] ? errors["email"].message : ""}
                    {...register("email")}
                    {...field}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                defaultValue=""
                name={"phone"}
                render={({ field }) => (
                  <TextField
                    className={styles.input}
                    label="Telefono"
                    size="small"
                    error={!!errors["phone"]}
                    helperText={errors["phone"] ? errors["phone"].message : ""}
                    {...register("phone")}
                    {...field}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </div>
            {idType === 3 ? (
              <div>
                <Controller
                  control={control}
                  defaultValue=""
                  name={"fullname"}
                  render={({ field }) => (
                    <TextField
                      className={styles.input}
                      label="Nombre del cliente"
                      size="small"
                      error={!!errors["fullname"]}
                      helperText={
                        errors["fullname"] ? errors["fullname"].message : ""
                      }
                      {...register("fullname")}
                      {...field}
                      autoComplete="off"
                    ></TextField>
                  )}
                />
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <Button
                className={styles.button}
                variant="contained"
                type="submit"
              >
                Crear Cliente
              </Button>
            </div>
            <div>
              <Button
                className={styles.button}
                variant="contained"
                color="error"
                onClick={() => showCreateClient(false)}
              >
                Cancelar
              </Button>
            </div>
          </ResponsiveGridLayout>
        </form>
      </Panel>
    </FormProvider>
  );
};
