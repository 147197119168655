import { useEffect, useState } from "react";
import { API_URL } from "../app/constants";
import api from "../app/interceptor";

const useFetch = (path: string, payload = {}, headers = {}) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [status, setStatus] = useState("loading");
  const [mustRefetch, refetch] = useState({});

  useEffect(() => {
    const fetchRequest = async (path: string, payload: any, headers: any) => {
      try {
        const response = await api.post(`${API_URL}${path}`, payload);
        setData(response.data);
        setStatus("done");
      } catch (error: any) {
        setStatus("error");
        setData(undefined);
        setError(error.response.data.error);
      }
    };

    fetchRequest(path, payload, headers);
  }, [path, mustRefetch]);

  return { data, error, status, refetch };
};

export default useFetch;
