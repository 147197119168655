import styles from "./Reports.module.css";

import { ProductSwitch, ProductSwitchItem } from "@ui5/webcomponents-react";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";

export function Reports() {
	/**
	 * Nagivation
	 */
	const history = useHistory();
	const [userCookie] = useCookies(["user"]);

	return (
		<div className={styles.reports}>
			<ProductSwitch>
				<ProductSwitchItem
					icon="customer"
					titleText="Clientes"
					onClick={() => history.push("customerReport")}
				/>
				<ProductSwitchItem
					icon="product"
					titleText="Productos"
					onClick={() => history.push("productReport")}
				/>
				<ProductSwitchItem
					icon="sales-order"
					titleText="Proformas"
					onClick={() => history.push("quotationReport")}
				/>
				<ProductSwitchItem
					icon="shipping-status"
					titleText="Envios"
					onClick={() => history.push("shippingReport")}
				/>
				<ProductSwitchItem
					icon="sales-quote"
					titleText="Facturas"
					onClick={() => history.push("billReport")}
				/>
				<ProductSwitchItem
					icon="notes"
					titleText="Notas"
					onClick={() => history.push("noteReport")}
				/>
				{userCookie && userCookie.user.toString() === "1" && (
					<ProductSwitchItem
						icon="inspect"
						titleText="Log"
						onClick={() => history.push("logReport")}
					/>
				)}
				{/* 
				<ProductSwitchItem icon="sales-order-item" titleText="Notas" />
				<ProductSwitchItem icon="delete" titleText="Anulaciones" /> */}
			</ProductSwitch>
		</div>
	);
}
