import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
// import { useState } from "react";

interface propsSearch {
  onclick(billuuid: string): void;
  setBillUUID: (value: string) => void;
  billUUID: string;
}

export const Search: React.FC<propsSearch> = ({
  onclick,
  setBillUUID,
  billUUID,
}) => {
  return (
    <Box>
      <TextField
        label="Buscar por Codigo/UUID"
        variant="standard"
        sx={{ width: "25rem" }}
        onChange={(e) => setBillUUID(e.target.value)}
        autoComplete="off"
      ></TextField>
      <Button
        variant="outlined"
        startIcon={<SearchIcon />}
        onClick={() => onclick(billUUID)}
      >
        Buscar
      </Button>
    </Box>
  );
};
