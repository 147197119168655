export interface CustomerDTO {
  customerId: number;
  nit: string;
  fullName: string;
  address: string;
  phone: string;
  internalCode: string;
  email: string;
  customerTypeId: number;
  sellerId: number;
  deliveryAddress: string;
  acceptCheck: boolean;
  saleInDollars: boolean;
  foreignCustomer: boolean;
  withholdVat: boolean;
  limitCredit: number;
  maximumDiscount: number;
  creditDaysId: number;
  about: string;
  dpi: string;
  passportid: string;
}

export const customerInitialState: CustomerDTO = {
  customerId: 0,
  nit: "",
  fullName: "",
  address: "",
  phone: "",
  internalCode: "",
  email: "",
  customerTypeId: 0,
  sellerId: 0,
  deliveryAddress: "",
  acceptCheck: false,
  saleInDollars: false,
  foreignCustomer: false,
  withholdVat: false,
  limitCredit: 0.0,
  maximumDiscount: 0.0,
  creditDaysId: 0,
  about: "",
  dpi: "",
  passportid: "",
};
