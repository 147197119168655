import {
  Alert,
  Button,
  Divider,
  MenuItem,
  TextField,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL } from "../../app/constants";
import { BillInfo, billInfoInitalValue } from "../../models/billInfo";
import { productPreviewInfo } from "../../models/productPreviewInfo";
import { Search } from "../../components/search/Search";
import { TableToolbar } from "../../components/table-toolbar/TableToolbar";
import { DynamicTable } from "../../components/dynamic-table/DynamicTable";
import { NotesForm } from "../../components/notes-form/NotesForm";
import { Note, noteInitialValue } from "../../models/Note.model";

export const FiscalNotes = () => {
  const [reason, setReason] = useState<string>("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState<any>(undefined);
  const [billHeader, setBillHeader] = useState<BillInfo>(billInfoInitalValue);
  const [billProducts, setBillProducts] = useState<productPreviewInfo[]>([]);
  const [listItems, setListItems] = useState<productPreviewInfo[]>([]);
  const [fiscalNote, setFiscalNote] = useState(1);
  const [billUUID, setBillUUID] = useState("");
  const [cookie] = useCookies(["access"]);
  const [nickname] = useCookies(["nickname"]);
  const [authToken, setAuthToken] = useState<string>("");
  const [noteGenerated, setNoteGenerated] = useState<boolean>(false);
  const [cleanData, setCleanData] = useState<boolean>(false);

  // used to store the created note
  const [note, setNote] = useState<Note>(noteInitialValue);
  const apiAccess = {
    headers: { Authorization: `Bearer ${cookie["access"]}` },
  };

  useEffect(() => {
    if (notificationType) {
      setTimeout(() => setNotificationType(undefined), 8000);
    }
  }, [notificationType]);

  useEffect(() => {
    setCleanData(false);
    setListItems([]);
  }, [cleanData]);

  const loadBillProducts = (products: any[]) => {
    setBillProducts([]);
    for (let i = 0; i < products.length; i++) {
      let temporal: productPreviewInfo = {
        ...products[i],
        maxquantity: products[i].quantity,
        status: false,
      };
      setBillProducts((current) => [...current, temporal]);
    }
  };

  const handleSelectFiscalNote = (e: any) => {
    setFiscalNote(e.target.value);
  };

  const handleFindBillByUUID = async (billuuid: string) => {
    setBillUUID(billuuid);
    if (billuuid) {
      await searchBillRequest(billuuid);
      setCleanData(true);
    }
  };

  const handleOnDeleteRow = (index: number) => {
    let temporalListItem = [...listItems];
    temporalListItem.splice(index, 1);
    setListItems(temporalListItem);
  };

  const searchBillRequest = async (authNumber: string) => {
    try {
      await axios
        .get(`${API_URL}/external/getBillInformation/${authNumber}`, apiAccess)
        .then((response) => {
          if (response.data.billHeader[0].billstatus) {
            setBillHeader(response.data.billHeader[0]);
            loadBillProducts(response.data.products);
            return;
          }

          setNotificationMessage(
            `Error! la factura que intenta consultar ha sido anulada.`
          );
          setNotificationType("error");
        })
        .catch((error) => {
          setNotificationMessage(
            `Ops! No se ha encontrado la factura. Ref:${error} `
          );
          setNotificationType("error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = (values: any) => {
    const iva = ((values.quantity * values.unitprice) / 1.12) * 0.12;
    const temporalItem: productPreviewInfo = {
      discount: 0,
      producttypeid: values.type,
      quantity: values.quantity,
      maxquantity: 1,
      quickoverview: values.overview,
      unitprice: Number(values.unitprice.toFixed(2)),
      iva: Number(iva.toFixed(2)),
      subtotal: Number(values.unitprice * values.quantity),
      status: false,
    };

    setListItems((item: any) => [...item, temporalItem]);
  };

  const handleOnSubmit = async () => {
    try {
      switch (fiscalNote) {
        case 1:
          creditNote();
          break;
        case 2:
          debitNote();
          break;
        case 3:
          installment();
          break;
      }
    } catch (error: any) {
      setNotificationMessage(error.toString());
      setNotificationType("error");
    }
  };

  const creditNote = () => {
    if (!validateCreditNote()) {
      setNotificationType("error");
      return;
    }

    try {
      sendRequest(`${API_URL}/external/notes/creditNote`, {
        creditNote: {
          currency: billHeader.currency,
          emissionDate: new Date().toISOString().replace("Z", ""),
          totalWithVat: getTotalVat(listItems).toFixed(4),
          grandTotal: getGranTotal(listItems).toFixed(4),
          emissionDocumentDate: new Date(billHeader.certificationdate)
            .toISOString()
            .split("T")[0],
          reason: reason,
          authNumber: billHeader.authorizationnumber,
          documentNumber: billHeader.documentnumber,
          documentSeries: billHeader.serienumber,
          nickname: nickname["nickname"],
          authToken: authToken,
        },
        customer: {
          fullname: billHeader.customername,
          email: billHeader.customeremail,
          address: billHeader.customeraddress,
          nit: billHeader.customernit,
        },
        items: [...listItems],
      });
    } catch (error: any) {
      setNotificationType("eror");
      setNotificationMessage(
        "Error al intentar hacer la peticion. Comunicate con IT"
      );
      console.log(error);
    }
  };

  const validateCreditNote = () => {
    const totalBill = getGranTotal(billProducts);
    const totalNote = getGranTotal(listItems);
    if (totalNote === 0) {
      setNotificationMessage("El importe de la nota no puede ser 0");
      return false;
    }
    if (totalNote > totalBill) {
      setNotificationMessage(
        "Error el importe total de la nota no puede exceder el total de la factura"
      );
      return false;
    }

    return true;
  };

  const debitNote = () => {
    console.log("nota de debito");
    if (!validateDebitNote()) {
      setNotificationType("error");
      return;
    }
    sendRequest(`${API_URL}/external/notes/debitNote`, {
      debitNote: {
        currency: billHeader.currency,
        emissionDate: new Date().toISOString().replace("Z", ""),
        totalWithVat: getTotalVat(listItems),
        grandTotal: getGranTotal(listItems),
        emissionDocumentDate: new Date(billHeader.certificationdate)
          .toISOString()
          .split("T")[0],
        reason: reason,
        authNumber: billHeader.authorizationnumber,
        documentNumber: billHeader.documentnumber,
        documentSeries: billHeader.serienumber,
        nickname: nickname["nickname"],
        authToken: authToken,
      },
      customer: {
        fullname: billHeader.customername,
        email: billHeader.customeremail,
        address: billHeader.customeraddress,
        nit: billHeader.customernit,
      },
      items: [...listItems],
    });
  };

  const validateDebitNote = () => {
    const totalNote = getGranTotal(listItems);
    if (totalNote === 0) {
      setNotificationMessage("El importe de la nota no puede ser 0");
      return false;
    }
    return true;
  };

  const installment = () => {
    if (!validateCreditNote()) {
      setNotificationType("error");
      return;
    }
    sendRequest(`${API_URL}/external/notes/installment`, {
      installment: {
        currency: billHeader.currency,
        emissionDate: new Date().toISOString().replace("Z", ""),
        totalWithVat: getTotalVat(listItems),
        grandTotal: getGranTotal(listItems),
        emissionDocumentDate: new Date(billHeader.certificationdate)
          .toISOString()
          .split("T")[0],
        reason: reason,
        authNumber: billHeader.authorizationnumber,
        documentNumber: billHeader.documentnumber,
        documentSeries: billHeader.serienumber,
        nickname: nickname["nickname"],
        authToken: authToken,
      },
      customer: {
        fullname: billHeader.customername,
        email: billHeader.customeremail,
        address: billHeader.customeraddress,
        nit: billHeader.customernit,
      },
      items: [...listItems],
    });
  };

  const sendRequest = async (url: string, body: any) => {
    try {
      await axios
        .post(url, body, apiAccess)
        .then((response) => {
          setNotificationMessage("Operacion realizada con exito");
          setNotificationType("success");
          setReason("");
          setAuthToken("");
          setNote(response.data.data);

          setNoteGenerated(true);
        })
        .catch((error) => {
          setNotificationMessage(
            `${error.response.data.error}: ${error.response.data.message}`
          );
          setNotificationType("error");
          setNoteGenerated(false);
          setNote(noteInitialValue);
        });
    } catch (error: any) {
      setNotificationMessage(`Fatal error 500 `);
      setNotificationType("error");
    }
  };

  const getGranTotal = (listOfItems: any[]) => {
    let total = 0;
    for (let i = 0; i < listOfItems.length; i++) {
      total += Number(listOfItems[i].unitprice * listOfItems[i].quantity);
    }
    return total;
  };

  const getTotalVat = (list: productPreviewInfo[]) => {
    let total = getGranTotal(list);
    let totalVat = (total / 1.12) * 0.12;
    return totalVat;
  };

  const showNote = () => {
    console.log(note);
    if (note.noteid !== -1) {
      viewFile(
        `${API_URL}/external/notes/note-report/${note.notetype}/${note.noteid}`
      );
    }
  };

  /**
   * Opens an file
   * @param url File url
   */
  const viewFile = async (url: any) => {
    fetch(url, apiAccess)
      .then((response) => response.blob())
      .then((blob) => {
        let _url: any = window.URL.createObjectURL(blob);
        window.open(_url, "_blank")?.focus();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <section>
        <Search
          onclick={handleFindBillByUUID}
          setBillUUID={setBillUUID}
          billUUID={billUUID}
        />
      </section>
      {billProducts.length > 0 && (
        <Box>
          <section>
            <Divider
              textAlign="left"
              sx={{ margin: "1rem", fontSize: "2rem", color: "#5d6d7e" }}
            >
              Seleccionar tipo de nota
            </Divider>
            <TextField
              label="Nota fiscal"
              sx={{ marginTop: "1rem", width: "30%" }}
              onChange={(e) => handleSelectFiscalNote(e)}
              value={fiscalNote}
              select
            >
              <MenuItem value={1} key={1}>
                Nota de Credito
              </MenuItem>
              <MenuItem value={2} key={2}>
                Nota de Debito
              </MenuItem>
              <MenuItem value={3} key={3}>
                Nota de Abono
              </MenuItem>
            </TextField>
          </section>

          <section>
            <Divider
              textAlign="center"
              sx={{ margin: "1rem", fontSize: "2rem", color: "#5d6d7e" }}
            >
              Información de Factura
            </Divider>
            <Box sx={{ backgroundColor: "#E5E7E9", width: "100%" }}>
              <TableToolbar bill={billHeader} />
              <DynamicTable
                headers={[
                  "Tipo",
                  "Cantidad",
                  "Descripcion",
                  "Valor Unitario",
                  "Total",
                ]}
                tableContent={billProducts.map(
                  ({ maxquantity, discount, iva, ...item }) => item
                )}
              />
            </Box>
          </section>

          <Divider
            textAlign="center"
            sx={{ margin: "1rem", fontSize: "2rem", color: "#5d6d7e" }}
          >
            Agregar Elementos
          </Divider>

          <NotesForm onSubmit={addNewItem} />

          {listItems.length > 0 && (
            <div>
              <Divider
                textAlign="left"
                sx={{ margin: "1rem", fontSize: "2rem", color: "#5d6d7e" }}
              >
                Elementos agregados a la Nota
              </Divider>
              <DynamicTable
                headers={[
                  "Tipo",
                  "Cantidad",
                  "Descripcion",
                  "Valor Unitario",
                  "Iva",
                  "Total",
                ]}
                tableContent={listItems.map(
                  ({ maxquantity, discount, ...item }) => item
                )}
                tableActions={[
                  {
                    props: {
                      startIcon: <DeleteIcon />,
                      variant: "text",
                      color: "error",
                      sx: { marginTop: "0.5rem" },
                      disabled: false,
                    },
                    callback: (index: number) => handleOnDeleteRow(index),
                  },
                ]}
                spanTableData={[
                  {
                    key: "Total",
                    value: `${billHeader.currency} ${parseFloat(
                      getGranTotal(listItems).toFixed(4)
                    )}`,
                    span: 4,
                    sx: { fontSize: "30px" },
                  },
                ]}
              />
            </div>
          )}
          <Divider
            textAlign="center"
            sx={{ margin: "1rem", fontSize: "2rem", color: "#5d6d7e" }}
          >
            Justificacion y Autorización
          </Divider>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {" "}
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "50%" }}
                  multiline
                  label="Justificacion"
                  required
                  placeholder="Agrega aqui una justificación clara de la razón de este documento."
                  variant="outlined"
                  rows={1}
                  onBlur={(e) => setReason(e.target.value.toString())}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{ width: "50%" }}
                  label="Autorización"
                  variant="outlined"
                  required
                  error={authToken === "" ? true : false}
                  helperText={!!authToken ? "Campo obligatorio" : ""}
                  onBlur={(e) => setAuthToken(e.target.value.toString())}
                />
              </Grid>
              <Grid item xs={4} md={6}>
                <Button
                  variant="contained"
                  sx={{
                    width: "50%",
                  }}
                  color="primary"
                  type="submit"
                  onClick={handleOnSubmit}
                >
                  Generar Nota
                </Button>
              </Grid>
              <Grid item xs={4} md={6}>
                <Button
                  sx={{ width: "50%" }}
                  variant="outlined"
                  onClick={showNote}
                  disabled={note.noteid === -1}
                >
                  Ver Nota
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {notificationType && (
        <Alert
          sx={{ marginTop: "1rem" }}
          severity={notificationType}
          variant="filled"
        >
          {notificationMessage}
        </Alert>
      )}
    </div>
  );
};
