import styles from "./AnnulmentComponent.module.css";
import {
  ResponsiveGridLayout,
  Input,
  Icon,
  Button,
  Panel,
  ComboBox,
  ComboBoxItem,
  TextArea,
  Loader,
} from "@ui5/webcomponents-react";

interface IannulmentComponent {
  annulment: any;
  setAnnulment: (annulment: any) => void;
  handleAnnulmentReasonChange: (value: any) => void;
  getAnnulmentReasonValue: () => string;
  annulmentReasons: any[];
  handleAnnulment: () => void;
  annuling: boolean;
  showAnnulment: () => void;
  printAnnulment: () => void;
}

export const AnnulmentComponent: React.FC<IannulmentComponent> = ({
  annulment,
  setAnnulment,
  handleAnnulmentReasonChange,
  getAnnulmentReasonValue,
  annulmentReasons,
  handleAnnulment,
  annuling,
  showAnnulment,
  printAnnulment,
}) => {
  /**
   * Converts date to locale
   * @param date Unclean date
   * @returns Clean date
   */
  const cleanDate = (date: any): string => {
    if (date === undefined || date === "" || date === null) {
      return "";
    }
    let result = new Date(date.toString());
    return result.toLocaleString("es-GT").split(" ")[0];
  };

  return (
    <>
      <Panel headerText="Información de Documento">
        <ResponsiveGridLayout
          columnsXL={2}
          columnsL={2}
          columnsM={1}
          columnsS={1}
        >
          <div>
            <strong>Numero de Autorización</strong>
            <Input
              icon={<Icon name="number-sign" />}
              className={styles.input}
              readonly
              value={
                annulment.authorizationnumber
                  ? annulment.authorizationnumber
                  : ""
              }
            />
          </div>
          <div>
            <strong>Serie-Documento</strong>
            <Input
              icon={<Icon name="number-sign" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={
                annulment.documentseries
                  ? `${annulment.documentseries}-${annulment.documentnumber}`
                  : ""
              }
            />
          </div>
          <div>
            <strong>Tipo de DTE y Estado</strong>
            <Input
              icon={<Icon name="document" />}
              className={styles.input}
              readonly
              value={`${annulment.billtype}${
                annulment.billtype !== ""
                  ? annulment.annulmentid === 0
                    ? " - Activo"
                    : " - Inactivo"
                  : ""
              }`}
            />
          </div>
          <div>
            <strong>Fecha de Emisión</strong>
            <Input
              icon={<Icon name="date-time" />}
              className={styles.input}
              readonly
              value={
                annulment.emissiondate ? cleanDate(annulment.emissiondate) : ""
              }
            />
          </div>
          <div>
            <strong>NIT del Receptor</strong>
            <Input
              icon={<Icon name="employee" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={annulment.receivernit ? annulment.receivernit : ""}
            />
          </div>
          <div>
            <strong>Nombre del Receptor</strong>
            <Input
              icon={<Icon name="retail-store" />}
              onInput={function noRefCheck() {}}
              className={styles.input}
              readonly
              value={
                annulment.receiverfullname ? annulment.receiverfullname : ""
              }
            />
          </div>
          <div>
            <strong>Comentarios</strong>
            <TextArea
              className={styles.input}
              onInput={(e) =>
                setAnnulment({
                  ...annulment,
                  comments: e.target.value.toString(),
                })
              }
            />
          </div>
          <div>
            <strong>Razón de Anulación</strong>
            <ComboBox
              icon={<Icon name="cause" />}
              onChange={(e) => handleAnnulmentReasonChange(e.target.value)}
              placeholder="Razón de Anulación"
              className={styles.input}
              value={getAnnulmentReasonValue()}
              style={{
                border:
                  annulment.annulmentreasonid === 0 ? "2px solid red" : "",
              }}
            >
              {annulmentReasons.map((annulmentReason) => (
                <ComboBoxItem
                  key={annulmentReason.annulmentreasonid}
                  text={annulmentReason.name}
                />
              ))}
            </ComboBox>
          </div>
          <div>
            <Button
              icon="delete"
              onClick={handleAnnulment}
              iconEnd
              className={styles.button}
              design="Negative"
              disabled={
                annuling || annulment.annulmentid !== 0 
              }
            >
              Anular
            </Button>
            {annuling && <Loader />}
          </div>
          {annulment.annulmentid !== 0 && (
            <div>
              <Button
                icon="detail-view"
                onClick={showAnnulment}
                iconEnd
                className={styles.button}
                design="Positive"
              >
                Visualizar Anulación
              </Button>
            </div>
          )}
          {annulment.annulmentid !== 0 && (
            <div>
              <Button
                icon="print"
                onClick={printAnnulment}
                iconEnd
                className={styles.button}
                design="Positive"
              >
                Imprimir Anulación
              </Button>
            </div>
          )}
        </ResponsiveGridLayout>
      </Panel>
    </>
  );
};
