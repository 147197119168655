import styles from "./ClientInfo.module.css";
import {
  Panel,
  Input,
  Icon,
  ResponsiveGridLayout,
  SuggestionItem,
} from "@ui5/webcomponents-react";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "@ui5/webcomponents/dist/features/InputSuggestions.js";

import { Customer } from "../../models/customer.model";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";

interface IclientInfo {
  disabled: boolean;
  filteredCustomers: Customer[];
  customer: Customer;
  setCustomerId: (input: string) => void;
  handleGetFilteredCustomers: (e: any) => void;
  findCustomerById: () => void;
  getCustomerCreditDays: () => string;
  showAddButton: boolean;
  showCreateClient: (value: boolean) => void;
}

export const ClientInfo: React.FC<IclientInfo> = ({
  disabled,
  filteredCustomers,
  customer,
  setCustomerId,
  handleGetFilteredCustomers,
  findCustomerById,
  getCustomerCreditDays,
  showAddButton,
  showCreateClient,
}) => {
  return (
    <Panel className={styles.headerText} headerText="Cliente">
      <ResponsiveGridLayout
        columnsXL={2}
        columnsL={2}
        columnsM={1}
        columnsS={1}
      >
        <div>
          <Input
            showSuggestions
            className={styles.input}
            icon={<Icon name="customer" />}
            onChange={(e) => {
              console.log(`id selected customer: `, e.target.value.toString());
              setCustomerId(e.target.value.toString());
            }}
            onInput={(e) => handleGetFilteredCustomers(e)}
            disabled={disabled}
          >
            {filteredCustomers.map((customer: Customer, i: number) => (
              <SuggestionItem
                key={i}
                text={customer.fullname}
                additionalText={`Nit:${customer.nit}`}
              />
            ))}
          </Input>
        </div>
        <div>
          <Button
            endIcon={<SearchIcon />}
            onClick={() => findCustomerById()}
            className={styles.button}
            variant="outlined"
            disabled={disabled}
          >
            Buscar Cliente
          </Button>
          {showAddButton && (
            <Button
              variant="outlined"
              endIcon={<PersonAddOutlinedIcon />}
              className={styles.button}
              onClick={() => showCreateClient(true)}
            >
              Crear Cliente
            </Button>
          )}
        </div>
        <div>
          <Input
            icon={<Icon name="customer" />}
            placeholder="Nombre Cliente"
            className={styles.input}
            readonly
            value={
              customer.fullname
                ? `${customer.fullname} - ${
                    customer.nit === ""
                      ? customer.dpi === ""
                        ? customer.dpi
                        : customer.passportid
                      : customer.nit
                  }  `
                : ""
            }
          />
        </div>
        <div>
          <Input
            icon={<Icon name="call" />}
            placeholder="Teléfono"
            className={styles.input}
            readonly
            value={customer.phone ? customer.phone : ""}
          />
        </div>
        <div>
          <Input
            icon={<Icon name="email" />}
            placeholder="Correo"
            className={styles.input}
            readonly
            value={customer.email ? customer.email : ""}
          />
        </div>
        <div>
          <Input
            icon={<Icon name="addresses" />}
            placeholder="Dirección"
            className={styles.input}
            readonly
            value={customer.address ? customer.address : ""}
          />
        </div>
        <div>
          <Input
            icon={<Icon name="appointment" />}
            placeholder="Días de Crédito"
            className={styles.input}
            readonly
            value={customer.creditdaysid ? getCustomerCreditDays() : ""}
          />
        </div>
        <div>
          <Input
            icon={<Icon name="lead" />}
            placeholder="Monto Maximo de Credito"
            className={styles.input}
            readonly
            value={
              customer.limitcredit
                ? parseFloat(customer.limitcredit.toString()).toFixed(2)
                : ""
            }
          />
        </div>
      </ResponsiveGridLayout>
    </Panel>
  );
};
