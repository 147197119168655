export interface AnnulmentDTO {
	annulmentid: number;
	authorizationnumber: string;
	documentid: string;
	documentseries: string;
	documentnumber: string;
	billtype: string;
	emissiondate: string;
	receivernit: string;
	receiverfullname: string;
	comments: string;
	annulmentreasonid: number;
	annulmentdate: string;
}

export const annulmentInitialState: AnnulmentDTO = {
	annulmentid: 0,
	authorizationnumber: "",
	documentid: "",
	documentseries: "",
	documentnumber: "",
	billtype: "",
	emissiondate: "",
	receivernit: "",
	receiverfullname: "",
	comments: "",
	annulmentreasonid: 0,
	annulmentdate: "",
};
