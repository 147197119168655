export interface Note {
  noteid?: number;
	billid?: number;
	quotationid?: number;
	certificationresult?: string;
	certifieddocument?: string;
	qrcode?: string;
	nitcertifier?: string;
	namecertifier?: string;
	authorizationnumber?: string;
	documentnumber?: string;
	documentseries?: string;
	certificationdate?: string;
	lastdate?: string;
	notetype?: string;
	exchangerate?: string;
	status?: boolean;
}


export const noteInitialValue: Note ={
  noteid: -1, 
	billid: 0,
	quotationid: 0,
	certificationresult: "",
	certifieddocument: "",
	qrcode: "",
	nitcertifier: "",
	namecertifier: "",
	authorizationnumber: "",
	documentnumber: "",
	documentseries: "",
	certificationdate: "",
	lastdate: "",
	notetype: "",
	exchangerate: "",
	status: false,
}
