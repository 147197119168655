import { useEffect } from "react";
import { TextField, Button, Grid, Box, MenuItem } from "@mui/material";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { zodResolver } from "@hookform/resolvers/zod";
import { TypeOf } from "zod";
import * as z from "zod";

interface INotesForm {
  onSubmit: (values: any) => void;
}

const schema = z.object({
  quantity: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: "Expected number, received a string",
  }),
  description: z.string().min(1, { message: "Campo Obligatorio" }),
  unitprice: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: "Expected number, received a string",
  }),
  type: z.number(),
});

const noteType = [
  {
    key: 1,
    value: "Bien",
  },
  {
    key: 2,
    value: "Servicio",
  },
];

type schemaInput = TypeOf<typeof schema>;

export const NotesForm: React.FC<INotesForm> = ({ onSubmit }) => {
  const methods = useForm<schemaInput>({ resolver: zodResolver(schema) });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<schemaInput> = (values) => {
    onSubmit({
      quantity: Number(values.quantity),
      overview: values.description,
      unitprice: Number(values.unitprice),
      type: Number(values.type),
    });
  };

  return (
    <Box sx={{ m: "1rem" }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item md={5}>
              <Controller
                control={control}
                name="quantity"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Cantidad"
                    variant="standard"
                    required
                    sx={{ width: "100%" }}
                    error={!!errors["quantity"]}
                    helperText={
                      errors["quantity"] ? errors["quantity"].message : ""
                    }
                    {...field}
                    {...register("quantity")}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item sm={5}>
              <Controller
                control={control}
                name="description"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Descripción"
                    variant="standard"
                    required
                    sx={{ width: "100%" }}
                    error={!!errors["description"]}
                    helperText={
                      errors["description"] ? errors["description"].message : ""
                    }
                    {...field}
                    {...register("description")}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item md={5}>
              <Controller
                control={control}
                name="unitprice"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Precio Unitario"
                    variant="standard"
                    sx={{ width: "100%" }}
                    required
                    error={!!errors["unitprice"]}
                    helperText={
                      errors["unitprice"] ? errors["unitprice"].message : ""
                    }
                    {...field}
                    {...register("unitprice")}
                    autoComplete="off"
                  ></TextField>
                )}
              />
            </Grid>
            <Grid item md={5}>
              <Controller
                control={control}
                name="type"
                defaultValue={1}
                render={({ field }) => (
                  <TextField
                    label="Tipo"
                    variant="standard"
                    select
                    sx={{ width: "100%" }}
                    error={!!errors["type"]}
                    helperText={errors["type"] ? errors["type"].message : ""}
                    {...field}
                    {...register("type")}
                  >
                    {noteType.map((item) => (
                      <MenuItem value={item.key} key={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item sm={4}>
              <Button type="submit" variant="outlined" startIcon={<AddIcon />}>
                AGREGAR ELEMENTO
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
