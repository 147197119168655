import {
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Box,
} from "@mui/material";
import {HeaderTable} from "../header-table/HeaderTable";

interface tableProps {
  tableHeader: string[];
  tableBody: any;
  sx: {};
}

export const TableComponent: React.FC<tableProps> = ({
  tableHeader,
  tableBody,
  sx,
}) => {
  return (
    <Box sx={sx}>
      <TableContainer component={Paper}>
        <Table>
          <HeaderTable headerNames={tableHeader} />
          <TableBody>
            {tableBody.map((e: any) => (
              <TableRow>
                {Object.values(e).map((values) => (
                  <TableCell align="center"> {values} </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
