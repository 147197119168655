import axios from "axios";
import cookie from "react-cookie";
import { API_URL } from "./constants";

const api = axios.create({
  baseURL: API_URL || "http://localhost:3000",
});

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const cookie = document.cookie;
    const rawAccess = cookie.match(/access=.*/)?.[0];
    if (rawAccess) {
      const token = rawAccess?.split("=");
      const apiAccess = {
        headers: { Authorization: `Bearer ${token[1]}` },
      };

      config.headers = { Authorization: `Bearer ${token[1]}` };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
