import styles from "./ExportInvoice.module.css";

import {
	Panel,
	Input,
	Button,
	Grid,
	Table,
	TableColumn,
	Label,
	TableRow,
	TableCell,
	Title,
} from "@ui5/webcomponents-react";

export function ExportInvoice() {
	return (
		<div className={styles.exportInvoice}>
			<Panel headerText="1. Seleccionar Cliente">
				<Grid position="Center">
					<div data-layout-span="XL9 L9 M9 S12">
						<Input
							onChange={function noRefCheck() {}}
							onInput={function noRefCheck() {}}
							onSuggestionItemPreview={function noRefCheck() {}}
							onSuggestionItemSelect={function noRefCheck() {}}
							onSuggestionScroll={function noRefCheck() {}}
							style={{ width: "100%" }}
							placeholder="NIT"
						/>
					</div>
					<div data-layout-span="XL3 L3 M3 S12">
						<Button
							icon="search"
							onClick={function noRefCheck() {}}
							style={{ width: "100%" }}
						>
							Buscar
						</Button>
					</div>
					<div data-layout-span="XL12 L12 M12 S12">
						<Table
							columns={
								<>
									<TableColumn>
										<Label>NIT</Label>
									</TableColumn>
									<TableColumn>
										<Label>Nombre</Label>
									</TableColumn>
									<TableColumn>
										<Label>Dirección</Label>
									</TableColumn>
									<TableColumn>
										<Label>Correo</Label>
									</TableColumn>
								</>
							}
							onLoadMore={function noRefCheck() {}}
							onPopinChange={function noRefCheck() {}}
							onRowClick={function noRefCheck() {}}
							onSelectionChange={function noRefCheck() {}}
						>
							<TableRow>
								<TableCell>
									<Label>10000607</Label>
								</TableCell>
								<TableCell>
									<Label>Otoniel Salguero Alvarez</Label>
								</TableCell>
								<TableCell>
									<Label>
										7 CALLE 8-62 Zona 4, COLONIA MEDICA, Villa Canales,
										GUATEMALA
									</Label>
								</TableCell>
								<TableCell>
									<Label>N/A</Label>
								</TableCell>
							</TableRow>
						</Table>
					</div>
				</Grid>
			</Panel>
			<Panel headerText="2. Agregar Productos" style={{ marginTop: "20px" }}>
				<Grid position="Center">
					<div data-layout-span="XL9 L9 M9 S12">
						<Input
							onChange={function noRefCheck() {}}
							onInput={function noRefCheck() {}}
							onSuggestionItemPreview={function noRefCheck() {}}
							onSuggestionItemSelect={function noRefCheck() {}}
							onSuggestionScroll={function noRefCheck() {}}
							style={{ width: "100%" }}
							placeholder="Nombre o código"
						/>
					</div>
					<div data-layout-span="XL3 L3 M3 S12">
						<Button
							icon="search"
							onClick={function noRefCheck() {}}
							style={{ width: "100%" }}
						>
							Buscar
						</Button>
					</div>
					<div data-layout-span="XL12 L12 M12 S12">
						<Title level="H6">Productos encontrados</Title>
						<Table
							columns={
								<>
									<TableColumn style={{ width: "12rem" }}>
										<Label>Product</Label>
									</TableColumn>
									<TableColumn minWidth={800} popinText="Supplier">
										<Label>Supplier</Label>
									</TableColumn>
									<TableColumn
										demandPopin
										minWidth={600}
										popinText="Dimensions"
									>
										<Label>Dimensions</Label>
									</TableColumn>
									<TableColumn demandPopin minWidth={600} popinText="Weight">
										<Label>Weight</Label>
									</TableColumn>
									<TableColumn>
										<Label>Price</Label>
									</TableColumn>
									<TableColumn demandPopin minWidth={600} popinText="Actions">
										<Label>Actions</Label>
									</TableColumn>
								</>
							}
							onLoadMore={function noRefCheck() {}}
							onPopinChange={function noRefCheck() {}}
							onRowClick={function noRefCheck() {}}
							onSelectionChange={function noRefCheck() {}}
						>
							<TableRow>
								<TableCell>
									<Label>Notebook Basic</Label>
								</TableCell>
								<TableCell>
									<Label>Very Best Screens</Label>
								</TableCell>
								<TableCell>
									<Label>30 x 18 x 3cm</Label>
								</TableCell>
								<TableCell>
									<Label>4.2KG</Label>
								</TableCell>
								<TableCell>
									<Label>956EUR</Label>
								</TableCell>
								<TableCell>
									<Button icon="add-product" onClick={function noRefCheck() {}}>
										Add
									</Button>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Label>Notebook Basic 17HT-1001</Label>
								</TableCell>
								<TableCell>
									<Label>Very Best Screens</Label>
								</TableCell>
								<TableCell>
									<Label>29 x 17 x 3.1cm</Label>
								</TableCell>
								<TableCell>
									<Label>4.5KG</Label>
								</TableCell>
								<TableCell>
									<Label>1249EUR</Label>
								</TableCell>
								<TableCell>
									<Button icon="add-product" onClick={function noRefCheck() {}}>
										Add
									</Button>
								</TableCell>
							</TableRow>
						</Table>
						<Title level="H6" style={{ marginTop: "15px" }}>
							Productos agregados
						</Title>
						<Table
							columns={
								<>
									<TableColumn style={{ width: "12rem" }}>
										<Label>Product</Label>
									</TableColumn>
									<TableColumn minWidth={800} popinText="Supplier">
										<Label>Supplier</Label>
									</TableColumn>
									<TableColumn
										demandPopin
										minWidth={600}
										popinText="Dimensions"
									>
										<Label>Dimensions</Label>
									</TableColumn>
									<TableColumn demandPopin minWidth={600} popinText="Weight">
										<Label>Weight</Label>
									</TableColumn>
									<TableColumn>
										<Label>Price</Label>
									</TableColumn>
									<TableColumn demandPopin minWidth={600} popinText="Actions">
										<Label>Actions</Label>
									</TableColumn>
								</>
							}
							onLoadMore={function noRefCheck() {}}
							onPopinChange={function noRefCheck() {}}
							onRowClick={function noRefCheck() {}}
							onSelectionChange={function noRefCheck() {}}
						>
							<TableRow>
								<TableCell>
									<Label>Notebook Basic</Label>
								</TableCell>
								<TableCell>
									<Label>Very Best Screens</Label>
								</TableCell>
								<TableCell>
									<Label>30 x 18 x 3cm</Label>
								</TableCell>
								<TableCell>
									<Label>4.2KG</Label>
								</TableCell>
								<TableCell>
									<Label>956EUR</Label>
								</TableCell>
								<TableCell>
									<Button
										icon="delete"
										onClick={function noRefCheck() {}}
										design="Negative"
									>
										Remove
									</Button>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Label>Notebook Basic 17HT-1001</Label>
								</TableCell>
								<TableCell>
									<Label>Very Best Screens</Label>
								</TableCell>
								<TableCell>
									<Label>29 x 17 x 3.1cm</Label>
								</TableCell>
								<TableCell>
									<Label>4.5KG</Label>
								</TableCell>
								<TableCell>
									<Label>1249EUR</Label>
								</TableCell>
								<TableCell>
									<Button
										icon="delete"
										onClick={function noRefCheck() {}}
										design="Negative"
									>
										Remove
									</Button>
								</TableCell>
							</TableRow>
						</Table>
					</div>
				</Grid>
			</Panel>
			<Panel headerText="3. Confirmar orden">
				<Grid position="Center">
					<div data-layout-span="XL9 L9 M9 S12">
						<Input
							onChange={function noRefCheck() {}}
							onInput={function noRefCheck() {}}
							onSuggestionItemPreview={function noRefCheck() {}}
							onSuggestionItemSelect={function noRefCheck() {}}
							onSuggestionScroll={function noRefCheck() {}}
							style={{ width: "100%" }}
							placeholder="Escriba 'confirmar' para confirmar la orden."
						/>
					</div>
					<div data-layout-span="XL3 L3 M3 S12">
						<Button
							icon="create"
							onClick={function noRefCheck() {}}
							style={{ width: "100%" }}
							design="Emphasized"
						>
							Generar orden
						</Button>
					</div>
				</Grid>
			</Panel>
		</div>
	);
}
